
// Demo enrollment app
import bus from "./messages"
import {Applicant, ApplicantTypeChild, Coverage, CoverageList, PremiumMode} from "./models";
import {DateValidator} from "../utils";
import moment from "../vendor/moment-timezone-with-data-1970-2030.js";



let textFormFieldTemplate = `
  <b-form-group :label="display_name" :state="validationState" :invalid-feedback="invalidFeedback" :required="!optional">
    <template v-slot:label v-html="display_html" v-if="display_html"></template>
    <b-form-input ref="input" type="text" class="form-control" v-model="value" :trim="true" :state="validationState" :required="!optional"></b-form-input>
    
  </b-form-group>
   `;
let textAreaFormFieldTemplate = `
  <b-form-group :label="display_name" :state="validationState" :invalid-feedback="invalidFeedback" :required="!optional">
    <b-form-textarea ref="input" class="form-control" v-model="value" :trim="true" :state="validationState" :required="!optional"></b-form-textarea>
    
  </b-form-group>
`;
let dateTemplate = `
  <b-form-group :label="display_name" :state="validationState" :invalid-feedback="invalidFeedback" :required="!optional">
    <b-form-input ref="input" type="date" class="form-control" v-model="value" :state="validationState" :required="!optional" :min="minimum" :max="maximum"></b-form-input>
  </b-form-group>
   `;

let emailTemplate = `
  <b-form-group :label="display_name" :state="validationState" :invalid-feedback="invalidFeedback" :required="!optional">
      <b-form-input ref="input" type="email" class="form-control" v-model="value" :trim="true" :state="validationState" :required="!optional"></b-form-input>
  </b-form-group>
`;

let integerTemplate = `
  <b-form-group :label="display_name" :state="validationState" :invalid-feedback="invalidFeedback" :required="!optional">
    <b-input-group :prepend="input_group_prepend" :append="input_group_append">
      <b-form-input ref="input" type="number" v-model="value" :number="true" :state="validationState" :required="!optional" :min="minimum" :max="maximum" :step="step"></b-form-input>
    </b-input-group>
  </b-form-group>
`;

let passwordTemplate = `
  <b-form-group :label="display_name" :state="validationState" :invalid-feedback="invalidFeedback" :required="!optional">
      <b-form-input ref="input" type="password" class="form-control" v-model="value" :trim="true"  :state="validationState" :required="!optional"></b-form-input>
  </b-form-group>
`;

let ssnTemplate = `
  <b-form-group :label="display_name" :state="validationState" :invalid-feedback="invalidFeedback" :required="!optional"> 
      <b-form-input ref="input" type="text" class="form-control" v-mask="'000-00-0000'" v-model="value" :trim="true" :required="!optional" :state="validationState" :readonly="readonly"></b-form-input>
  </b-form-group>
`;

let phoneTemplate = `
  <b-form-group :label="display_name" :state="validationState" :invalid-feedback="invalidFeedback" :required="!optional"> 
      <b-form-input ref="input" type="text" class="form-control" v-mask="'000-000-0000'" v-model="value" :trim="true" :required="!optional" :state="validationState"></b-form-input>
  </b-form-group>
`;

let zipCodeTemplate = `
  <b-form-group :label="display_name" :state="validationState" :invalid-feedback="invalidFeedback" :required="!optional"> 
      <b-form-input ref="input" type="text" class="form-control" v-mask="'00000-0000'" v-model="value" :trim="true" :required="!optional" :state="validationState"></b-form-input>
  </b-form-group>
`;

let salaryTemplate = `
  <b-form-group :label="display_name" :state="validationState" :invalid-feedback="invalidFeedback" :required="!optional"> 
    <b-input-group prepend="$">
      <b-form-input ref="input" type="text" class="form-control" v-model="value" :number="true" :required="!optional" :state="validationState"></b-form-input>
    </b-input-group>
  </b-form-group>
`;

let currencyTemplate = `
  <b-form-group :label="display_name" :state="validationState" :invalid-feedback="invalidFeedback" :required="!optional"> 
    
<!--        v-currency="{-->
<!--          currency: 'USD',-->
<!--          locale: 'en',-->
<!--          distractionFree: false,-->
<!--          min: minimum,-->
<!--          max: maximum,-->
<!--          validateOnInput: false,-->
<!--          precision: {min:2, max:precision}-->
<!--        }"-->
      <b-form-input
        v-currency="{
            locale: 'en',
            currency: 'USD',
            min: minimum,
            max: maximum,
            precision: {min:2, max:precision},
            valueRange: { min: 0 },
            allowNegative: false
          }"
        v-model="value" 
        ref="input"
        :number="true" :state="validationState" :required="!optional"
      ></b-form-input>
  </b-form-group>
`;


let checkboxTemplate = `
<b-form-group :state="validationState" :disabled="disabled">
  <b-form-checkbox 
   ref="input"
    :name="name"
    v-model="value" 
    :state="validationState"
    :required="!optional"
    :plain="true"
    :disabled="disabled"
    > {{ display_name }}
  </b-form-checkbox>
</b-form-group>
`;

let multiCheckboxTemplate = `
  <b-form-group :label="display_name" :state="validationState" :invalid-feedback="invalidFeedback">
    <b-form-checkbox-group 
      v-model="value"
      :options="options"
      :state="validationState"
    ></b-form-checkbox-group>
  </b-form-group>
`;

let multiSelectTemplate = `
  <b-form-group :label="display_name" :invalid-feedback="invalidFeedback">
    <b-form-select multiple v-model="value" :options="options" :state="validationState" ></b-form-select>
</b-form-group>
`;


let selectTemplate = `
  <b-form-group :label="display_name" :invalid-feedback="invalidFeedback" :disabled="disabled">
    <b-form-select v-model="value" :options="options" :state="validationState" :required="!optional"></b-form-select>
</b-form-group>
`;

let radioTemplate = `
<b-form-group :label="display_name" :invalid-feedback="invalidFeedback" :state="validationState" >
  <b-form-radio-group
          buttons
          button-variant="outline-success"
          :name="localFormName"
          v-model="value"
          :options="options"
          :state="validationState"
          :validated="validated"
          :required="!optional"
          :stacked="isStackedDisplay"
          >
  </b-form-radio-group>
</b-form-group>
`;

let radioTemplateNotButtons = `
<b-form-group :label="display_name" :invalid-feedback="invalidFeedback">
  <b-form-radio-group
          :name="name + '_' + applicantContext.id || coverageContext.product.id"
          v-model="value"
          :options="options"
          :disabled="disabled"
          :stacked="isStackedDisplay"
          :required="!optional"
          :validated="validated"
          :state="validationState"
          >
  </b-form-radio-group>
</b-form-group>
`;

let radioYesNoTemplate = `
<b-form-group :label="display_name" :state="validationState" 
        :invalid-feedback="invalidFeedback">
  <template v-slot:label v-html="display_html" v-if="display_html"></template>
        
  <b-form-radio-group
        buttons
        button-variant="outline-success"
        :name="name"
        :state="validationState"
        :validated="validated"
        :required="!optional"
        v-model="value"
        :options="[{'text': 'Yes', 'value': 'yes'}, {'text': 'No', 'value': 'no'}]"
        >
</b-form-radio-group>
</b-form-group>
`;

function baseCheckValidationState() {
  // Always touch this.value for reactivity
  let currentVal = this.value;

  if (this.$refs.input && this.$refs.input.$el && typeof this.$refs.input.$el.setCustomValidity === 'function') {
    // Reset custom error messages using HTML5 error API
    this.$refs.input.$el.setCustomValidity("");
  }

  let inputElement = (this.$refs.input && this.$refs.input.$el) ? this.$refs.input.$el : null;

  let nativeIsValid = null;
  if (!this.optional && this.validated && inputElement && inputElement.checkValidity) {
    return inputElement.checkValidity();
  }

  if (!this.optional && this.value !== '' && this.value !== null) {
   return true;
  } else if (!this.optional && (this.value === '' || this.value === null) && this.validated) {
   return false;
  }

  return null;
}

function baseInvalidFeedback() {
  if (!this.optional && (this.value === '' || this.value === null)) {
    return "Please fill out this field.";
  } else if (this.$refs.input && this.$refs.input.$el &&
    ( ! this.$refs.input.$el.checkValidity || ( this.$refs.input.$el.checkValidity && this.$refs.input.$el.checkValidity() === false))) {
    return this.$refs.input.$el.validationMessage;
  }
  return "";
}


function make_field_component(name, template) {
  return {
    name: name,
    template: template,
    props: [
      "display_name",
      "display_html",
      "name",
      "initial_value",
      "minimum",
      "maximum",
      "optional",
      "affects_premium",
      "step",
      "precision",
      "readonly",

      // these are not used by all components - EF 2022-09-28
      "input_group_prepend",
      "input_group_append",
    ],
    data: function() {
      return {
        value: (this.initial_value !== null && this.initial_value !== undefined) ? this.initial_value : null,
        changed: false,
        validated: false
      }
    },
    created() {
      // Always broadcast our initial value
      this.emitValue();
      if (this.affects_premium) {
        this.emitUpdatePremium();
      }
      bus.$on("validate", this.handleValidate);
    },
    watch: {
      value() {
        this.emitValue();
        this.changed = true;
        if (this.affects_premium) {
          this.emitUpdatePremium();
        }
      }
    },
    methods: {
      emitValue() {
        this.$emit("value-change", {name: this.name, value: this.value});
      },
      emitUpdatePremium() {
        bus.$emit("update-premium");
      },
      handleValidate() {
        this.validated = true;
      },

      setCustomInvalidFeedbackIfHTML5Validation() {
        if (this.$refs.input && this.$refs.input.$el) {
          // Set custom error message using HTML5 error API
          this.$refs.input.$el.setCustomValidity(this.invalidFeedback);
        }
      }
    },
    computed: {
      validationState: baseCheckValidationState,
      invalidFeedback: baseInvalidFeedback,
      // precision() {
      //   if (this.precision) {
      //     return this.precision
      //   }
      //   if (this.step) {
      //     const stepStr = "" + this.step;
      //     if (stepStr.indexOf(".") > -1) {
      //       return stepStr.split(".").pop().length
      //     }
      //   }
      // }
    }
  }
}

function make_multiselect_field_component(name, template, default_value=null) {
  return {
   name: name,
   template: template,
    props: [
      "display_name",
      "display_html",
      "name",
      "initial_value",
      "options",
      "optional",
      "affects_premium",
      "disabled",
      "coverageContext",
      "applicantContext",
      "isStackedDisplay",
    ],
    data: function() {
      return {
        value: (this.initial_value !== null && this.initial_value !== undefined) ? this.initial_value : default_value,
        validated: false,
      }
    },
    created() {
      // Always broadcast our initial value
      this.emitValue();
      if (this.affects_premium) {
        this.emitUpdatePremium();
      }
      bus.$on("validate", this.handleValidate);
    },
    watch: {
      value() {
        this.emitValue();
        if (this.affects_premium) {
          this.emitUpdatePremium();
        }
      }
    },
    methods: {
      emitValue() {
        this.$emit("value-change", {name: this.name, value: this.value});
      },
      emitUpdatePremium() {
        bus.$emit("update-premium");
      },
      handleValidate() {
        this.validated = true;
      },
    },
    computed: {
      validationState: baseCheckValidationState,
      invalidFeedback: baseInvalidFeedback,
      localFormName() {
        if (!name) {
          return null;
        }
        let local = '' + name;
        let formId = 0;

        if (this.applicantContext && this.applicantContext.id) {
          formId = this.applicantContext.id;
        } else if (this.coverageContext && this.coverageContext.applicant && this.coverageContext.applicant.id) {
          formId = this.coverageContext.applicant.id;
        } else if (this.coverageContext && this.coverageContext.product && this.coverageContext.product.id) {
          formId = this.coverageContext.product.id;
        }

        local += `_${formId}`;
        return local;
      },
    }
  }
}

let enrollTextInput=  make_field_component("EnrollTextInput", textFormFieldTemplate);
let enrollTextAreaInput = make_field_component("EnrollTextAreaInput", textAreaFormFieldTemplate);
let enrollDateInput = make_field_component("EnrollDateInput", dateTemplate);

// Customize date input


// Add basic date validation since browser datepickers are not uniformly good at allowing only valid dates through.
enrollDateInput.computed.validationState = function() {
  let val = this.value;
  let baseCheckResult = baseCheckValidationState.call(this);
  let dateValidator = new DateValidator({minDateString: this.minimum, maxDateString: this.maximum});

  if ((baseCheckResult === false) || (val && !dateValidator.validate(val).isValid)) {
    this.setCustomInvalidFeedbackIfHTML5Validation();
    return false;
  }

  return null;
};
enrollDateInput.computed.invalidFeedback = function() {
  let val = this.value;
  let dateValidator = new DateValidator({minDateString: this.minimum, maxDateString: this.maximum});
  const validationResult = dateValidator.validate(val);

  if (!validationResult.isValid) {
    return validationResult.feedback;
  }

  return baseInvalidFeedback.call(this);
};

// Specific validation for Birthdate inputs

let enrollBirthdateInput = make_field_component("EnrollBirthdateInput", dateTemplate);
enrollBirthdateInput.computed.validationState = function() {
  let val = this.value;
  let dateValidator = new DateValidator({maxDateString: moment().format("Y-M-D")});
  if (val && dateValidator.validate(val).isValid === false) {
    this.setCustomInvalidFeedbackIfHTML5Validation();
    return false;
  }
  return baseCheckValidationState.call(this);
};
enrollBirthdateInput.computed.invalidFeedback = function() {
  let val = this.value;
  let dateValidator = new DateValidator({maxDateString: moment().format("Y-M-D")});
  const validationResult = dateValidator.validate(val);
  if (val && validationResult.isValid === false) {
    return validationResult.feedback;
  }
  return baseInvalidFeedback.call(this);
};

let enrollEmailInput = make_field_component("EnrollEmailInput", emailTemplate);

let passwordTextInput=  make_field_component("PasswordTextInput", passwordTemplate);
let integerInput = make_field_component("EnrollIntegerInput", integerTemplate);
let ssnInput = make_field_component("SSNInput", ssnTemplate);
let phoneInput = make_field_component("PhoneInput", phoneTemplate);
let zipCodeInput = make_field_component("ZipCodeInput", zipCodeTemplate);
let salaryInput = make_field_component('SalaryInput', salaryTemplate);
let currencyInput = make_field_component("EnrollCurrencyInput", currencyTemplate);

// Modify currency emit method to strip off '$' sign
currencyInput.methods.emitValue = function() {
  let modifiedValue = this.value;
  if (typeof modifiedValue === 'string' && modifiedValue.length > 0) {
    if (modifiedValue[0] === '$') {
      modifiedValue = Number.parseFloat(modifiedValue.substr(1));
    } else {
      modifiedValue = Number.parseFloat(modifiedValue);
    }
  }

  if (modifiedValue) {
    // this will round the value to the number of decimal places passed
    modifiedValue = modifiedValue.toFixed(this.precision)
  }

  this.$emit("value-change", {name: this.name, value: modifiedValue});
};

let checkboxInput = make_multiselect_field_component("EnrollCheckboxInput", checkboxTemplate);
let multiCheckboxInput = make_multiselect_field_component("EnrollMultiCheckboxInput", multiCheckboxTemplate, []);
let multiSelectInput = make_multiselect_field_component("EnrollMultiSelectInput", multiSelectTemplate, []);
let selectInput = make_multiselect_field_component("EnrollSelectInput", selectTemplate);
let radioInput = make_multiselect_field_component("EnrollRadioInput", radioTemplate);
let nonButtonsRadioInput = make_multiselect_field_component("EnrollStandardRadioInput", radioTemplateNotButtons);
let radioYesNo = make_multiselect_field_component("EnrollYesNoInput", radioYesNoTemplate);


let coverageQuestionLabel = {
  name: 'CoverageQuestionLabel',
  props: [
    "text",
    "display_html",
  ],
  template: `
<b-row>
  <b-col>
    <b-form-group>
      <template v-slot:label>
        <div v-if="display_html && display_html.length > 0" v-html="display_html"></div>
        <div v-else>{{ text }}</div>
      </template>
    </b-form-group>
  </b-col>
</b-row>`,
};

let coverageQuestionHeader = {
  name: 'CoverageQuestionLabel',
  props: [
    "text",
    "display_html",
  ],
  template: `
<b-row>
  <b-col>
    <div v-if="display_html && display_html.length > 0" v-html="display_html"></div>
    <div v-else>{{ text }}</div>
  </b-col>
</b-row>`,
};

let sectionLabel = {
  name: 'SectionLabel',
  props: [
    "text"
  ],
  template: `
<b-row class="mb-0 mt-2">
  <b-col>
    <h5>{{ text }}</h5>
  </b-col>  
</b-row>
  `
};

let staticContent = {
  name: 'StaticContent',
  props: [
    'html'
  ],
  template: `
<b-row class="mb-3">
  <b-col>
    <div v-html="html"></div>
  </b-col>
</b-row>
`
};


let coverageQuestionTextArea = {
  name: "CoverageQuestionTextArea",
  props: [
    'name',
    'initial_value',
    'display_name',
    'optional',
    'coverageContext',
    'selectedCoverages',
  ],
  components: {
    'enroll-text-area': enrollTextAreaInput
  },
  data: function() {
    return {};
  },
  template: `
  <b-form-group horizontal 
    :label="coverageContext.applicant.getFirstName()"
    label-text-align="right"
    >
    <enroll-text-area 
      :name="name" 
      :display_name="display_name"
      :initial_value="initial_value" 
      :optional="optional" 
      :coverageContext="coverageContext"
      :selectedCoverages="selectedCoverages"
      @value-change="emitValue"
      ></enroll-text-area>
  </b-form-group>
  `,
  methods: {
    emitValue(val) {
      // Pass event along
      this.$emit("value-change", val);
    },
  }
};

let coverageQuestionYesNo = {
  name: "CoverageQuestionYesNo",
  props: {
    name: {type: String, required: true},
    yesDetailsRequired: {type: Boolean, required: false},
    noDetailsRequired: {type: Boolean, required: false},
    alertIfYes: {type: String, required: false},
    alertIfNo: {type: String, required: false},
    knockoutIfYes: {type: String, required: false},
    knockoutIfNo: {type: String, required: false},
    knockoutChangesValues: {type: Array, required: false},
    disqualifiesPremiumTierIfYes: {type: Object, required: false},
    disqualifiesPremiumTierIfNo: {type: Object, required: false},
    initial_value: {required: false},
    optional: {type: Boolean, required: false},
    coverageContext: {type: Coverage, required: true},
    detailsLabel: {type: String}
  },
  data: function() {
    let answer = null;
    let detailsAnswer = null;
    if (this.initial_value && this.initial_value.answer !== undefined) {
      answer = this.initial_value.answer;
    }
    if (this.initial_value && this.initial_value.detailsAnswer !== undefined) {
      detailsAnswer = this.initial_value.detailsAnswer;
    }
    return {
      answer: answer,
      detailsAnswer: detailsAnswer,
      validated: false,
      alertModalShowing: false,
      knockoutModalShowing: false,
      premiumTierDisqualificationModalShowing: false,
    };
  },
  template: `
<div>
<b-form-group horizontal 
    :label="coverageContext.applicant.getFirstName()"
    label-text-align="right"
    :invalid-feedback="invalidFeedbackButtons"
    :state="validationState"
    >
  
  <b-form-radio-group
          buttons
          :button-variant="buttonVariant"
          :name="name + '_'+ coverageContext.applicant.id"
          v-model="answer"
          :required="!optional"
          :state="validationState"
          >
    <b-form-radio type="radio" :value="true" :required="!optional" :state="validationState">Yes</b-form-radio>
    <b-form-radio type="radio" :value="false" :required="!optional" :state="validationState">No</b-form-radio>
  </b-form-radio-group>
  <transition name="fade" mode="out-in">
    <div v-if="shouldShowDetailsBox">
      <b-form-group
        :label="detailsLabel"
        :invalid-feedback="invalidFeedbackDetails"
        :state="validationStateDetails"
        :required="!optional && shouldShowDetailsBox"
        >
        
        <b-form-textarea
                       v-model="detailsAnswer"
                       placeholder="Type your response here"
                       :rows="3"
                       :max-rows="6"
                       :state="validationStateDetails"
                       :required="!optional && shouldShowDetailsBox"
                       >
        </b-form-textarea>
      </b-form-group>
    </div>
  </transition>
</b-form-group>
<b-modal v-if="hasAlert" v-model="alertModalShowing" ok-only title="Notice">
  {{ alertMessage }}
</b-modal>
<b-modal 
    v-if="hasKnockout" 
    v-model="knockoutModalShowing" 
    title="Notice"
    ok-title="I understand, continue"
    ok-variant="danger"
    cancel-title="Undo"
    cancel-variant="default"
    @cancel="handleUndoAnswer"
    @ok="handleKnockoutConfirm"
  no-close-on-esc
  no-close-on-backdrop
  hide-header-close
>
  {{ knockoutMessage }}
  
</b-modal>
<b-modal 
    v-if="hasPremiumTierDisqualification" 
    v-model="premiumTierDisqualificationModalShowing" 
    title="Notice"
    ok-title="I understand, continue"
    ok-variant="warning"
    cancel-title="Undo"
    cancel-variant="default"
    @cancel="handleUndoAnswer"
    @ok="handlePremiumTierDisqualifiedOk"
    
  no-close-on-esc
  no-close-on-backdrop
  hide-header-close
>
  {{ premiumTierDisqualificationMessage }}
  
</b-modal>
</div>
  `,
  created() {
    bus.$on("validate", this.onValidated)
  },
  watch: {
    answer() {
      this.emitValue();

      if (this.alertIfNo && this.answer === false) {
        this.alertModalShowing = true;
      } else if (this.alertIfYes && this.answer === true) {
        this.alertModalShowing = true;
      }
      if (this.knockoutIfNo && this.answer === false) {
        this.knockoutModalShowing = true;
      } else if (this.knockoutIfYes && this.answer === true) {
        this.knockoutModalShowing = true;
      }
      if (this.disqualifiesPremiumTierIfNo && this.answer === false) {
        this.premiumTierDisqualificationModalShowing = true;
      } else if (this.disqualifiesPremiumTierIfYes && this.answer === true) {
        this.premiumTierDisqualificationModalShowing = true;
      }
      // Always check the rates after changing an answer to a premium tier modification question
      if (this.disqualifiesPremiumTierIfNo || this.disqualifiesPremiumTierIfYes) {
        bus.$emit('update-premium');
      }
    },
    detailsAnswer() {
      this.emitValue();
    }
  },
  methods: {
    emitValue() {
      this.$emit("value-change", {coverage: this.coverageContext, name: this.name, value: this.serialize()})
    },

    serialize() {
      return {
        answer: this.answer,
        detailsAnswer: this.detailsAnswer
      }
    },
    onValidated() {
      this.validated = true;
    },
    handleUndoAnswer() {
      // reset the UI
      this.answer = null;
      // Emit a null for this answer
      this.$emit("value-change", {coverage: this.coverageContext, name: this.name, value: null})
    },
    handlePremiumTierDisqualifiedOk() {
      // We let the answer be recorded, maybe emit a premium recomputation? The backend will adjust the selected premium.
    },
    handleKnockoutConfirm() {
      if (this.knockoutChangesValues) {
        this.knockoutChangesValues.forEach((changeValue) => {
          this.$emit("value-change", {coverage: this.coverageContext, name: changeValue.name, value: changeValue.value})
        })
      }

      // Emit a null for this answer, so if the coverage is reselected, the knockout answer will need to be answered again.
      this.$emit("value-change", {coverage: this.coverageContext, name: this.name, value: null})


      // After the above values have settled, reload.
      this.$nextTick(() => {
        // Show that the total premium has changed due to coverage removal, then reload step.
        bus.$emit("update-premium")
        bus.$emit("reload-current-step")
      })
    }
  },
  computed: {
    shouldShowDetailsBox() {
      return (this.yesDetailsRequired && this.answer === true) || (this.noDetailsRequired && this.answer === false);
    },
    validationState() {
      if (!this.optional && this.validated && this.answer === null) {
        return false;
      } else if (!this.optional && this.answer !== null) {
        return true;
      }
    },
    validationStateDetails() {
      if (!this.optional && this.validated && this.detailsAnswer === null) {
        return false;
      } else if (!this.optional && this.detailsAnswer !== null) {
        return true;
      }
    },
    buttonVariant() {
      if (this.answer === true && this.knockoutIfYes) {
        return "outline-danger";
      } else if (this.answer === false && this.knockoutIfNo) {
        return "outline-danger";
      } else if (this.answer === true && this.disqualifiesPremiumTierIfYes) {
        return "outline-warning";
      } else if (this.answer === false && this.disqualifiesPremiumTierIfNo) {
        return "outline-warning";
      }

      return "outline-success";
    },
    invalidFeedbackButtons() {
      if (this.validationState === false) {
        return "This field is required.";
      }
      return "";
    },
    invalidFeedbackDetails() {
      if (this.validationStateDetails === false) {
        return "This field is required.";
      }
      return "";
    },
    hasAlert() {
      return this.alertIfYes || this.alertIfNo;
    },
    hasKnockout() {
      return this.knockoutIfYes || this.knockoutIfNo;
    },
    hasPremiumTierDisqualification() {
      return this.disqualifiesPremiumTierIfNo || this.disqualifiesPremiumTierIfYes
    },
    alertMessage() {
      if (this.alertIfYes && this.answer === true) {
        return this.alertIfYes;
      } else if (this.alertIfNo && this.answer === false) {
        return this.alertIfNo;
      }
      return "";
    },
    knockoutMessage() {
      if (this.knockoutIfYes && this.answer === true) {
        return this.knockoutIfYes;
      } else if (this.knockoutIfNo && this.answer === false) {
        return this.knockoutIfNo;
      }
      return "";
    },
    premiumTierDisqualificationMessage() {
      if (this.disqualifiesPremiumTierIfYes && this.answer === true) {
        const dqObj = this.disqualifiesPremiumTierIfYes;
        if (dqObj.disqualified.includes('standard')) {
          return `Please note: a 'Yes' answer to this question means that ${dqObj.applicant_name} will be considered only for the Graded Benefit coverage.`;
        } else {
          return `Please note: a 'Yes' answer to this question means that ${dqObj.applicant_name} will be considered for either Standard or Graded coverage.`;
        }

      } else if (this.disqualifiesPremiumTierIfNo && this.answer === false) {
        const dqObj = this.disqualifiesPremiumTierIfNo;
        if (dqObj.disqualified.includes('standard')) {
          return `Please note: a 'No' answer to this question means that ${dqObj.applicant_name} will be considered only for the Graded Benefit coverage.`;
        } else {
          return `Please note: a 'No' answer to this question means that ${dqObj.applicant_name} will be considered for either Standard or Graded coverage.`;
        }

      }
      return "";
    }
  }
};


let coveragePremiumDisplay = {
  name: "CoveragePremiumDisplay",
  template: `
      <span>
        <font-awesome-icon v-if="isLoading" icon="spinner" pulse></font-awesome-icon> 
        <span v-else v-html="display"></span>
      </span>
  `,
  props: [
    'premiumMode',
    'coverageContext',
    'policyRating'
  ],
  data: function() {
    return {
      isLoading: false,
      display: "",
    }
  },
  created() {
    bus.$on("update-premium", this.startLoading);
    bus.$on("updated-rates", this.displayUpdatedPremium);
  },
  methods: {
    startLoading() {
      // Todo: only show loading if this coverage was affected (as best we can tell)
      this.isLoading = true;
    },
    displayUpdatedPremium(rates) {

      let matchingRate = rates.find((r) => {
        return (r.applicant_id === this.coverageContext.applicant.id &&
          r.product_id === this.coverageContext.product.id)
      });

      if (matchingRate === undefined) {
        this.display = '';//'--.--';
      } else if (matchingRate.summary === undefined) {
        this.display = '';
      } else if (matchingRate.summary.premium_tiers !== undefined) {
        this.display = `$${matchingRate.summary.total_premium} <span class="badge badge-secondary">${this.policyRating}</span><br>`;
      } else {
        this.display = `$${matchingRate.summary.total_premium} ${this.premiumMode.text.toLowerCase()}`;
      }

      this.isLoading = false;
    }
  }
};


let heightInput = {
  name: "HeightInput",
  template: `
<b-form-group :label="display_name" :invalid-feedback="invalidFeedback" :required="!optional" :state="validationState">
  <b-row>
    <b-col cols="6"><b-form-select v-model="heightFeet" :options="feetOptions" :required="!optional" :state="validationState"></b-form-select></b-col>
    <b-col cols="6"><b-form-select v-model="heightInches" :options="inchesOptions" :required="!optional" :state="validationState"></b-form-select></b-col>
  </b-row>  
</b-form-group>
`,
  props: [
    "display_name",
    "name",
    "initial_value",
    "optional",
  ],
  created: function() {
    this.emitValue();
    bus.$on("validate", this.handleValidate);
  },
  data: function() {
    let feet_options = [{text: '(Select Feet)', value: null}];
    for (let f of [0, 1, 2, 3, 4, 5, 6, 7]) {
      feet_options.push({text: `${f} feet`, value: f});
    }
    let inches_options = [{text: '(Select Inches)', value: null}];
    for (let i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]) {
      inches_options.push({text: `${i} inches`, value: i});
    }

    // Convert initial_value to feet + inches, if not null
    let initial_feet = null;
    let initial_inches = null;
    if (this.initial_value && this.initial_value.feet >= 0) {
      initial_feet = this.initial_value.feet;
    }
    if (this.initial_value && this.initial_value.inches >= 0) {
      initial_inches = this.initial_value.inches;
    }

    return {
      'heightFeet': initial_feet,
      'heightInches': initial_inches,
      'feetOptions': feet_options,
      'inchesOptions': inches_options,
      'validated': (!!this.initial_value),
    }
  },
  watch: {
    heightFeet() {
      this.emitValue();
    },
    heightInches() {
      this.emitValue();
    }
  },
  methods: {
    emitValue() {
      this.$emit("value-change", {coverage: this.coverageContext, name: this.name, value: this.serialize()})
    },

    serialize() {
      return {
        total_inches: this.totalInches,
        feet: this.heightFeet,
        inches: this.heightInches
      }
    },
    handleValidate() {
      this.validated = true;
    },

  },
  computed: {
    totalInches() {
      if (this.heightInches === null || this.heightFeet === null) {
        return null;
      }
      return this.heightFeet * 12 + this.heightInches;
    },
    validationState() {

      if (this.validated && !this.optional) {
        if (this.totalInches !== null && this.totalInches > 0) {
          return true;
        } else {
          return false;
        }
      }

      return null;
    },
    invalidFeedback() {
      if (this.validationState === false) {
        return "This field is required.";
      }
      return ""
    }
  }
};


let GenericOptionCoverageSelection = {
  template: `
<div>
  <b-row>
  <h4 class="col">{{display_name}}</h4>
  </b-row>
  <b-row>
    <b-col>
      <b-form-select
            :options="options"
            v-model="selectedValue">
      </b-form-select>
    </b-col>
    <b-col class="col-form-label" v-if="!show_premium">
      <coverage-premium-display
        :coverageContext="coverageContext"
        :premiumMode="premiumMode"
      ></coverage-premium-display>
    </b-col>
  </b-row>
</div>
`,
  components: {
    'coverage-premium-display': coveragePremiumDisplay
  },
  props: {
    name: {type: String, required: true},
    coverageContext: {
      type: Coverage,
      required: true
    },
    coverageOptions: {
      type: Array,
      required: true
    },
    display_name: {
      type: String
    },
    initial_value: {
      type: Object
    },
    affects_premium: {
      type: Boolean
    },
    show_premium: {
      type: Boolean,
    },
    premiumMode: {
      type: PremiumMode,
      required: true
    }
  },
  data: function() {
    return {
      selectedValue: this.initial_value || null
    }
  },
  watch: {
    selectedValue() {
      this.$emit("value-change", {coverage: this.coverageContext, name: this.name, value: this.selectedValue});
      if (this.affects_premium) {
        bus.$emit("update-premium");
      }
    }
  },
  computed: {
    options() {
      let opts = [
        {text: "Waived", value: null}
      ];
      opts = opts.concat(this.coverageOptions.map((o) => {
        return {
          text: `${o.text}`,
          value: o,
        }
      }));
      return opts;
    },
    formattedPremium() {
      if (this.selectedValue === null) {
        return "$ --.--"
      } else {
        return `\$${this.selectedValue.premium} per month`;
      }
    }
  }
};


let Paragraph = {
  name: "Paragraph",
  props: [
    'text'
  ],
  template: `<div class="row" v-html="text"></div>`,
};


let fieldGroup = {
  props: [
    'initial_value',
    'fields'
  ],
  template: `
<b-form-row>
  <b-col v-for="field in fields" :offset="field.offset_twelfths || 0" class="mb-3" :key="field.name">
    <component 
      :is="field.type"
      :initial_value="initial_value"
      v-bind="field"
    ></component>
  </b-col>
</b-form-row>`,
  components: {
    'static-content': staticContent,
    'text-input': enrollTextInput,
    'integer-input': integerInput,
    'checkbox-input': checkboxInput,
    'multi-checkbox-input': multiCheckboxInput,
    'multi-select-input': multiSelectInput,
    'select-input': selectInput,
    'radio-input': radioInput,
    'section-label': sectionLabel,
  },
  methods: {
  }
};

export {
  make_field_component,
  enrollTextInput,
  enrollTextAreaInput,
  enrollDateInput,
  enrollBirthdateInput,
  salaryInput,
  currencyInput,
  enrollEmailInput,
  passwordTextInput,
  checkboxInput,
  multiCheckboxInput,
  multiSelectInput,
  selectInput,
  integerInput,
  ssnInput,
  phoneInput,
  zipCodeInput,
  radioInput,
  radioYesNo,
  coveragePremiumDisplay,
  coverageQuestionYesNo,
  coverageQuestionLabel,
  coverageQuestionHeader,
  coverageQuestionTextArea,
  sectionLabel,
  heightInput,
  Paragraph,
  nonButtonsRadioInput,
  staticContent,
  fieldGroup,
  GenericOptionCoverageSelection,
  baseCheckValidationState,
  baseInvalidFeedback
}
