<template>
  <div class="overflow-auto">
    <h2>Deliveries</h2>
    <b-alert
      :variant="alertVariant"
      dismissible
      :show="showAlert"
      v-text="alertMsg"></b-alert>

    <div v-show="!isLoading">
      <b-pagination
        v-model="currentPage"
        :total-rows="deliveries.length"
        :per-page="perPage"
        aria-controls="deliveries-table"
      ></b-pagination>
      <b-table :items="deliveries" :fields="fields" :per-page="perPage" :current-page="currentPage"
               sort-by="id" :sort-desc="true" striped hover responsive id="deliveries-table">
        <!--
          this is the actions cell slotted template
          - "actions" is the matching field key in `this.fields`
          - "row" is a variable that holds the information about the row data.
            We can access the correspoding row values at `rowdata.item`
        -->
        <template v-slot:cell(actions)="row">

          <b-button size="sm" variant="outline-primary" class="ml-1" @click="showDetails(row.item.id)">
            <font-awesome-icon icon="search"></font-awesome-icon>
            View
          </b-button>
          <b-button size="sm" variant="outline-primary" class="ml-1" @click="downloadDeliveryPackage(row.item.id)"
                    :disabled="row.item.downloading === true">
            <span v-if="row.item.downloading !== true"><font-awesome-icon icon="download"></font-awesome-icon> Download</span>
            <span v-else><font-awesome-icon icon="spinner" spin></font-awesome-icon> Download</span>
          </b-button>
          <b-button size="sm" variant="outline-primary" v-if="row.item.should_deliver" class="ml-1"
                    @click="requestDelivery(row.item.id)">
            <font-awesome-icon icon="upload"></font-awesome-icon>
            Deliver
          </b-button>
          <b-button size="sm" variant="outline-primary" v-if="row.item.can_redeliver"
                    @click="handleRequestRedelivery(row.item.id)" class="ml-1">
            <font-awesome-icon icon="upload"></font-awesome-icon>
            Re-Deliver
          </b-button>

        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="deliveries.length"
        :per-page="perPage"
        aria-controls="deliveries-table"
      ></b-pagination>

    </div>
    <loading-spinner v-show="isLoading" class="p-3"></loading-spinner>
  </div>
</template>

<script>
import formats from "../models/formats"
import bus from "../bus";
import {router, terminology} from "../app";
import Api from "../api";
import api from "../api";

export default {

  name: "deliveries",

  created() {
    this.getDeliveries();
  },

  watch: {
    deliveries: {
      handler: function (val) {
        console.debug("deliveries changed to", val);
      },
      deep: true
    }
  },

  computed: {
    showAlert() {
      return this.alertMsg && this.alertMsg.length > 0;
    }
  },

  methods: {

    getDeliveries() {
      this.isLoading = true;
      Api.getDeliveries()
        .then((resp) => {
          this.deliveries = resp;
          // now add the 'downloading' property to each delivery
          this.deliveries.forEach((delivery) => {
            delivery.downloading = false;
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showDetails(enrollmentId) {
      router.push({name: 'enrollmentDetails', params: {id: enrollmentId}});
    },

    handleRequestRedelivery(enrollmentId) {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to request re-delivery of ID ${enrollmentId}?`)
        .then(value => {
          console.log(`confirmed: ${value}`);
          if (!!value) {
            this.requestDelivery(enrollmentId, true);
          }
        })
    },

    /**
     * Request a redelivery of the given enrollment.
     * @param {Number} enrollmentId
     * @param {Boolean} redeliver
     */
    requestDelivery(enrollmentId, redeliver = false) {
      this.isLoading = true;
      this.alertMsg = '';
      this.alertVariant = 'success';
      Api.requestDelivery(enrollmentId)
        .then((resp) => {
          console.log('got response', resp);
          if (resp && resp.delivery_requested === true) {
            this.alertMsg = `Delivery requested for ID ${enrollmentId}`
          } else {
            console.error('Delivery request failed', resp);
            this.alertMsg = `Delivery request failed for ID ${enrollmentId}`
            // add errors from resp if present
            if (resp && resp.errors) {
              this.alertMsg += ': ' + resp.errors.join(', ');
            }
            this.alertVariant = 'warning';
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.getDeliveries();
        });
    },

    downloadDeliveryPackage(enrollmentID) {
      this.setDeliveryDownloading(enrollmentID, true);
      return api.downloadDeliveryPackage(enrollmentID)
        .then(async (response_blob) => {
          const url = window.URL.createObjectURL(response_blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `delivery_package_${enrollmentID}-nopdf.json`;
          link.click();
          URL.revokeObjectURL(url);
        })
        .finally(() => {
          this.setDeliveryDownloading(enrollmentID, false);
        });
    },
    setDeliveryDownloading(enrollmentId, state) {
      // find the index of the delivery with the enrollmentId
      const index = this.deliveries.findIndex((delivery) => {
        return delivery.id === enrollmentId;
      });

      console.log('setting delivery', enrollmentId, 'to downloading', state, 'at index', index);

      if (index > -1) {
        // set the 'downloading' property to the given state
        this.deliveries[index].downloading = state;
        console.log(`set delivery ${enrollmentId} to downloading ${state} at index ${index}`);
        this.deliveries = [...this.deliveries];
      }
    }
  },

  data: function () {
    return {
      isLoading: false,
      alertMsg: null,

      currentPage: 1,
      perPage: 100,

      deliveries: [],

      fields: [{
        key: 'id',
        sortable: true,
      }, {
        key: 'status',
        sortable: true,
      }, {
        key: 'delivery_required',
        label: 'Delivery Required?',
        formatter: (value, key, item) => {
          return formats.boolToYesNo(value)
        },
        sortable: true,
      }, {
        key: 'delivered_at',
        label: 'Delivered',
        formatter: (value, key, item) => {
          return formats.utcToDateTime(value, "n/a")
        },
        sortable: true,
      }, {
        key: 'actions',
        label: 'Actions',
        sortable: false,
        class: 'text-right',
      }],

      alertVariant: 'success'  // or 'warning'
    }
  }
}
</script>

<style scoped>
</style>
