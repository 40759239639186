<template>
  <b-overlay
    :show="showOverlay || (!haveDecision && !isFailed)"
    no-fade
    opacity="0.9"
    variant="light">

    <template #overlay> <!-- this is the custom overlay content so we can show a message -->
      <div class="text-center text-primary">
        <b-spinner type="border" small variant="primary"
                   :label="`Retrieving decision from underwriting service for ${coverageContext.applicant.getFirstName()}. Processing may take a few minutes.`"></b-spinner>
        Retrieving decision from underwriting service for {{ coverageContext.applicant.getFirstName() }}. Processing may take a few minutes.
      </div>
    </template>
    <b-card>
      <div v-if="shouldConfirmUnderwriting">
        <!-- underwriting decision info stuff -->
        <b-row class="mb-4">
          <b-col cols="12">
            <div>
              <div v-if="!haveDecision && !isFailed">
                <!-- do nothing! -->
              </div>

              <div v-if="haveDecision && !isFailed">

                <div v-if="isAccept">
                  <div v-if="isAsExpected">
                    Congratulations, {{ coverageContext.applicant.getFirstName() }}, you are eligible for coverage! Your
                    premium is shown
                    below.
                  </div>
                  <div v-else> <!-- the anticipated policy rating was not met -->
                    Congratulations, {{ coverageContext.applicant.getFirstName() }}, you are eligible for coverage!
                    Please
                    note, however,
                    that your premium was previously shown using our “{{ anticipatedPolicyRating }}” rates and
                    you have been approved for “{{ actualPolicyRating }}” coverage. Your new premium is shown
                    below.
                  </div>
                  <div v-if="isAcceptWithNoCoverageOptions">
                    <p>We're sorry, but our underwriting places {{ coverageContext.applicant.getFirstName() }} in our "{{ actualPolicyRating }}" rating, and
                      <span v-if="ineligibleByExistingCoverage">your existing policy coverage of $X already meets or exceeds the allowable limit at that rating which makes you ineligible for additional coverage.</span>
                      <span v-if="ineligibleByAge">your age exceeds the maximum eligible for this rating.</span>
                    </p>

                    <p>Thank you for considering CELIC Final Expense for your needs.</p>
                  </div>
                </div>

                <div v-if="isRefer"> <!-- Referred -->
                  At this time, underwriting for {{ coverageContext.applicant.getFirstName() }} is incomplete and must
                  be
                  referred to
                  CELIC’s Home Office. If you wish to continue pursuing coverage, please see your agent for next steps.
                </div>

                <div v-if="isDecline">
                  We’re sorry, but {{ coverageContext.applicant.getFirstName() }} is declined for coverage based on our
                  underwriting rules.
                  Thank you for considering CELIC Final Expense for your needs.
                </div>

                <UnderwritingDecisionDetails
                  v-if="enrollmentUnderwritingDecision.uw_policy_rating !== 'preferred'"
                  class="mt-4"
                  :underwriting-details="underwritingDecisionDetails">
                </UnderwritingDecisionDetails>

              </div>
              <div v-if="isFailed">
                <p>There was a problem retrieving the underwriting decision for {{coverageContext.applicant.getFirstName()}}.</p>

                <ul v-if="isFailedOnSubmission">
                  <li>The underwriting server returned an error when creating the underwriting case.</li>
                </ul>

                <p>Please try this {{ 'enrollment' | terminology({isTitle: true}) }} again later.</p>
              </div>

            </div>
          </b-col>
<!--          <b-col>-->
<!--            <b-button @click="refreshUnderwritingDecision" variant="outline-secondary" size="sm">-->
<!--              Refresh UWD-->
<!--            </b-button>-->
<!--          </b-col>-->
        </b-row>


        <!-- // END underwriting decision info stuff -->
      </div>

      <!-- coverage select row -->
      <div v-if="haveDecision && isAccept && hasCoverageOptions">
        <b-row>
          <b-col cols="3" class="text-right col-form-label">{{ coverageContext.applicant.getFirstName() }}</b-col>
          <b-col>
            <b-form-select
              :disabled="!isAccept"
              :options="options"
              v-model="selectedCoverageOption">
            </b-form-select>
          </b-col>
          <b-col class="col-form-label">
            <coverage-premium-display v-show="selectedCoverageOption !== null"
                                      :coverageContext="coverageContext"
                                      :premiumMode="premiumMode"
                                      :policyRating="actualPolicyRating || anticipatedPolicyRating"
            ></coverage-premium-display>
          </b-col>
        </b-row>
        <b-row v-if="existingCoverageAmount > 0">
          <b-col offset="3">
            <div class="text-danger mt-2">
              {{ this.coverageContext.applicant.getFirstName() }}'s available coverage reduced by ${{
                existingCoverageAmount
              }} of
              {{ this.existingCoverageReasonMsgs }}
              policy coverage.
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- // END coverage select row -->

    </b-card>
  </b-overlay>
</template>

<script>
import {Coverage, PremiumMode} from "./models";
import bus from "./messages";
import {coveragePremiumDisplay} from "./FormInput";
import Api from "../api";
import UnderwritingDecisionDetails from "./UnderwritingDecisionDetails";
import moment from "../vendor/moment-timezone-with-data-1970-2030";

// used by underwriting
const STATUS_CREATED = 'created';
const STATUS_AWAITING_RESPONSE = 'awaiting_response';
const STATUS_FINISHED = 'finished';
const STATUS_FAILED = 'failed';

const MAGNUM_DECISION_REFER = 'REFER';
const MAGNUM_DECISION_DECLINE = 'DECLINE';
const MAGNUM_DECISION_ACCEPT = 'ACCEPT';

const NTO_COVERAGE_VALUE = -999

const NULL_OPTION = {
  text: 'Waived',
  value: null
};

export default {
  name: "FaceValueCoverageSelection",
  components: {
    UnderwritingDecisionDetails,
    'coverage-premium-display': coveragePremiumDisplay
  },
  props: {
    name: {type: String, required: true},
    coverageContext: {
      type: Coverage,
      required: true
    },
    existingCoverageAmount: {
      type: Number,
      required: false,
      default: 0
    },
    existingCoverageDetails: {
      type: Array,
      required: false,
    },
    coverageOptions: {
      type: Array,
      required: true
    },
    initial_value: {
      required: false,
    },
    affects_premium: {
      type: Boolean
    },
    premiumMode: {
      type: PremiumMode,
      required: true
    },
    shouldConfirmUnderwriting: {
      type: Boolean,
      required: false,
      default: false
    },
    allUWCoverageOptions: {
      type: Object,
      required: false,
    },
    productId: {
      type: Number,
      required: false
    },
    partyId: {
      type: Number,
      required: false
    },
    addWaivedOption: {
      type: Boolean,
      required: false,
      default: true
    },
    addNoCoverageOption: {
      type: Boolean,
      required: false,
      default: false
    },
    ineligibleByAge: {
      type: Boolean,
      required: false,
      default: false
    },
    ineligibleByExistingCoverage: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  mounted() {
    bus.$emit("update-premium");

    if (this.shouldConfirmUnderwriting) {
      this.refreshUnderwritingDecision();
    } else {
      this.selectedCoverageOption = this.normalizeSelectedCoverageOptionValue(this.initial_value);
    }
  },
  data: function () {
    return {
      selectedCoverageOption: NULL_OPTION,

      // underwriting-only stuff
      showOverlay: false,
      enrollmentUnderwritingDecision: {
        created_at: null,
        enrollment_party_id: null,
        id: null,
        is_stale: false,
        is_failed_on_submission: false,
        is_submitted: true,
        product_id: -1,
        status: null,
        uw_policy_rating: null,
        status_last_updated: null,
        underwriter_decision_value: null,
        underwriter_id: null,
        underwriter_request: {},
        underwriter_response: {},
        updated_at: null,
      },
      referralReasons: [],
      underwritingDecisionDetails: [],
    }
  },
  watch: {
    selectedCoverageOption() {
      this.emitValue();
    },
    uwConfirmedCoverageSelectionValue() {
      this.emitValue();
    },
    haveDecision(val) {
      console.log("haveDecision", val, "Triggering get UW details API call");
      this.getUnderwritingDecisionDetails();
    },
    options: {
      handler() {
        if (this.selectedCoverageOption.value === null) {
          this.selectedCoverageOption = NULL_OPTION;  // normalize to the Waived/NULL option
        }

        if (this.selectedCoverageOption.value === null) {
          this.selectedCoverageOption = this.normalizeSelectedCoverageOptionValue(this.initial_value);
        } else {
          this.selectedCoverageOption = this.normalizeSelectedCoverageOptionValue(this.selectedCoverageOption);
        }
        console.log("because options changed, selectedCoverageOption updated:", this.selectedCoverageOption);
      },
      deep: true
    },
  },
  computed: {
    isAcceptWithNoCoverageOptions() {
      const value = this.isAccept && !this.hasCoverageOptions;
      console.debug(this.options, this.isAccept, this.hasCoverageOptions, value);
      return value;
    },
    hasCoverageOptions() {
      // return false if the only options in this.options are the waived option and the no coverage option
      return this.options.some(option => option.value !== null && option.value !== NTO_COVERAGE_VALUE);
    },
    options() {
      let opts = [];
      if (this.addWaivedOption) {
        opts.push({text: "Waived", value: null})
      }
      if (this.addNoCoverageOption) {
        opts.push({text: "No Coverage", value: NTO_COVERAGE_VALUE})
      }

      let coverageOptions = this.coverageOptions;

      if (this.allUWCoverageOptions) {
        if (this.actualPolicyRating && this.allUWCoverageOptions[this.actualPolicyRating]) {
          coverageOptions = this.allUWCoverageOptions[this.actualPolicyRating];
        } else if (this.anticipatedPolicyRating && this.allUWCoverageOptions[this.anticipatedPolicyRating]) {
          coverageOptions = this.allUWCoverageOptions[this.anticipatedPolicyRating];
        }
      }
      opts = opts.concat(coverageOptions).map((o) => {
        return {
          text: `${o.text}`,
          value: o,
        }
      });
      return opts;
    },
    formattedPremium() {
      if (this.selectedCoverageOption === null) {
        return "$ --.--"
      } else {
        return `\$${this.selectedCoverageOption.premium} per month`;
      }
    },

    // underwriting-only stuff
    haveDecision() {
      if (!this.shouldConfirmUnderwriting) {
        return true;
      }

      return !!this.enrollmentUnderwritingDecision.underwriter_decision_value;
    },
    isAccept() {
      if (!this.shouldConfirmUnderwriting) {
        return true;
      }

      if (!this.haveDecision) {
        return false;
      }
      return this.enrollmentUnderwritingDecision.underwriter_decision_value.toUpperCase() === MAGNUM_DECISION_ACCEPT;
    },
    isRefer() {
      if (!this.shouldConfirmUnderwriting) {
        return false;
      }

      if (!this.haveDecision) {
        return false;
      }
      return this.enrollmentUnderwritingDecision.underwriter_decision_value.toUpperCase() === MAGNUM_DECISION_REFER;
    },
    isDecline() {
      if (!this.shouldConfirmUnderwriting) {
        return false;
      }

      if (!this.haveDecision) {
        return false;
      }
      return this.enrollmentUnderwritingDecision.underwriter_decision_value.toUpperCase() === MAGNUM_DECISION_DECLINE;
    },
    isAsExpected() {
      if (!this.shouldConfirmUnderwriting) {
        return true;
      }

      // we don't even try to evaluate if it's NOT as expected unless we have a decision AND these rate objects are populated
      if (this.haveDecision) {
        if (this.actualPolicyRating) {
          return this.actualPolicyRating === this.anticipatedPolicyRating;
        }
      }
      return true;
    },

    anticipatedPolicyRating() {
      if (this.coverageContext.applicant && this.coverageContext.applicant.data.anticipated_policy_rating) {
        return this.coverageContext.applicant.data.anticipated_policy_rating;
      }
      // if (!this.applicantRates || !this.applicantRates.summary) {
      //   return;
      //   // return 'preferred'; // @TODO hardcoded magic string hack - EF 2022-07-12
      // }
      //
      // let applicantRates = this.applicantRates;
      //
      // if (applicantRates.summary && applicantRates.summary.anticipated_policy_rating) {
      //   return applicantRates.summary.anticipated_policy_rating;
      // }
    },

    actualPolicyRating() {
      return this.enrollmentUnderwritingDecision.uw_policy_rating;
    },

    // applicantRates() {
    //   let applicantRates = null;
    //
    //   if (!this.rates) {
    //     return;
    //   }
    //
    //   // get rates for this applicant
    //   for (let i = 0; i < this.rates.length; i++) {
    //     console.log("this.rates[i].applicant_id", this.rates[i].applicant_id);
    //     if (this.rates[i].applicant_id === this.applicantContext.id) {
    //       applicantRates = this.rates[i];
    //       break;
    //     }
    //   }
    //   return applicantRates;
    // },

    isFailed() {
      if (!this.shouldConfirmUnderwriting) {
        console.debug("isFailed: false because shouldConfirmUnderwriting is false");
        return false;
      }

      if (this.isFailedOnSubmission) {
        console.debug("isFailed: true because isFailedOnSubmission is true");
        return true;
      }

      return this.enrollmentUnderwritingDecision.status === STATUS_FAILED;
    },

    isFailedOnSubmission() {
      if (!this.shouldConfirmUnderwriting) {
        return false;
      }

      return !!this.enrollmentUnderwritingDecision.is_failed_on_submission;
    },

    existingCoverageReasonMsgs() {
      return [...new Set(
        this.existingCoverageDetails.map((ecd) => {
          return ecd.reason_applies_msg;
        })
      )].join(", ");
    },
    uwConfirmedCoverageSelectionFieldName() {
      return `${this.name}_uw_confirmed`;
    },
    uwConfirmedCoverageSelectionValue() {
      if (this.haveDecision) {
        if (this.isAccept && this.selectedCoverageOption && this.selectedCoverageOption.value) {
          return true;
        }
      }
      return false;
    },

  },
  methods: {
    normalizeSelectedCoverageOptionValue(newCoverageOptionValue) {

      if (!newCoverageOptionValue) {
        console.info("newCoverageOptionValue is null");
        return NULL_OPTION;
      }

      // find a match by value
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].value.value === newCoverageOptionValue.value) {
          return this.options[i].value;
        }
      }

      let currentLowValue = null;
      let currentLowOption = null;
      for (let i = 0; i < this.options.length; i++) {
        if (!currentLowValue || this.options[i].value.value < currentLowValue) {
          currentLowValue = this.options[i].value.value;
          currentLowOption = this.options[i].value;
        }
      }

      let currentHighValue = null;
      let currentHighOption = null;
      for (let i = 0; i < this.options.length; i++) {
        if (!currentHighValue || this.options[i].value.value > currentHighValue) {
          currentHighValue = this.options[i].value.value;
          currentHighOption = this.options[i].value;
        }
      }

      if (newCoverageOptionValue.value < currentLowValue) {
        return currentLowOption;
      }

      if (newCoverageOptionValue.value > currentHighValue) {
        return currentHighOption;
      }

      return newCoverageOptionValue;
    },

    refreshUnderwritingDecision() {
      if (!this.productId || !this.partyId) {
        console.log("refreshUnderwritingDecision: missing productId or partyId");
        return Promise.resolve();
      }

      this.showOverlay = true;

      return Api.getCurrentUnderwritingDecisionByProductParty(this.productId, this.partyId)
        .then((eudResp) => {
          if (eudResp && eudResp.status === 204) {
            console.log("refreshUnderwritingDecision: no decision found (204)");
            this.enrollmentUnderwritingDecision = null;
            this.showOverlay = false;
            return;
          }

          if (eudResp && !eudResp.errors) {
            this.enrollmentUnderwritingDecision = eudResp;
            console.log("refreshUnderwritingDecision: got eudResp", eudResp);
            this.getUnderwritingDecisionDetails();
          }
        })
        .finally(() => {
          this.showOverlay = false;

          // check if we should retry
          if (!this.haveDecision && !this.isFailed) {
            // sleep for 4000ms
            console.debug("sleeping for 4000ms");
            setTimeout(() => {
              // check if we have a decision, and re-run if not
              if (!this.haveDecision && !this.isFailed) {
                console.log("haveDecision and isFailed is false; re-running refreshUnderwritingDecision");
                return this.refreshUnderwritingDecision(this.productId, this.partyId);
              }
            }, 4000);
          }
        });
    },

    getUnderwritingDecisionDetails() {
      Api.getCurrentUnderwritingDecisionDetailsByProductParty(this.productId, this.partyId)
        .then(response => {
          console.log("getUnderwritingDecisionDetailsByProductParty response", response);
          if (response && !response.errors) {
            this.underwritingDecisionDetails = response;
          }
        })
        .catch(error => {
          console.log("getUnderwritingDecisionDetailsByProductParty error", error);
        });
    },

    emitValue() {
      this.$emit("value-change", {coverage: this.coverageContext, name: this.name, value: this.selectedCoverageOption});
      console.log("selectedCoverageOption changed", this.selectedCoverageOption);
      if (this.shouldConfirmUnderwriting) {
        // this emits up to the step component to set the data value for uw_confirmed
        this.$emit("value-change", {
          coverage: this.coverageContext,
          name: this.uwConfirmedCoverageSelectionFieldName,
          value: this.uwConfirmedCoverageSelectionValue
        });
        console.log("uwConfirmedCoverageSelectionFieldName changed", this.uwConfirmedCoverageSelectionFieldName);
      }
      // if (this.affects_premium) {
      bus.$emit("update-premium");
      // }
    }
  }
};
</script>

<style scoped>

</style>
