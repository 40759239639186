<template>
  <div id="reports">

    <loading-spinner v-show="isLoading" useWholePage></loading-spinner>

    <div v-show="!isLoading">
      <div class="flex-direction-row col-12 py-3 px-0 px-md-3 justify-content-between">

        <h3 class="col p-0">
          Reports
        </h3>

        <div class="flex-direction-row col-12 col-sm-0 p-0">
          <div
            class="col-0"
            v-if="reports.length > 0">
            <b-form-select
              v-model="selectedReport"
              :options="reportOptions">
            </b-form-select>
          </div>

          <b-btn
            size="md"
            class="ml-3"
            variant="success"
            @click="openNewReportModal()">
            <font-awesome-icon icon="plus"></font-awesome-icon> New Report
          </b-btn>
        </div>

      </div>

      <b-card
              class="pt-0"
        :bg-variant="colorDefaults.card.bgColor"
        :header-text-variant="colorDefaults.card.headerTextColor"
        :header-bg-variant="colorDefaults.card.headerBgColor"
        v-if="selectedReport !== null">

        <h5 slot="header" class="mb-0">
          {{ selectedReport.name }}

          <b-btn
            size="sm"
            class="float-right"
            variant=""
            :pressed="isGeneratingReportDownload"
            :disabled="isGeneratingReportDownload"
            @click="downloadReport()">
              <i v-show="!isGeneratingReportDownload" class="fa fa-download fa-fw"></i>
              <i v-show="isGeneratingReportDownload" class="fa fa-spinner fa-spin fa-fw"></i>
            Download
          </b-btn>

          <b-button size="sm" class="float-right"
                    :pressed="isEditingReport"
                    @click="isEditingReport = !isEditingReport" >
            <font-awesome-icon icon="cog"></font-awesome-icon> Edit
          </b-button>

        </h5>

        <transition name="slideDown" mode="out-in">
          <div v-if="isEditingReport">
            <filter-editor

                    :report="selectedReport"
                    :cases="cases"
                    @apply-and-save="handleApplyAndSave"
                    @apply="handleApply"
                    @delete-report="handleDeleteReport"
            ></filter-editor>

            <hr>

          </div>
        </transition>

         <enrollments-table
          v-if="selectedReport !== null"
          :enrollments="enrollments"
          :tableProps="tableProps"
          v-on:search-enrollments-listener="refreshSelectedReport">
        </enrollments-table>

      </b-card>



    </div>

    <b-modal
      title="Create Report"
      v-model="showNewReportModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true">

      <b-container>
        <div class="column">
          <div class="form-group col-6 p-0">
            <label>Name:</label>
            <b-form-input
              class="col"
              v-model="newReportName"
              type="text"
              placeholder="">
            </b-form-input>
          </div>
        </div>

        <div v-if="reportErrors.length>0" class="mt-2 mb-2">
          <b>Please correct the following error<span v-if="reportErrors.length>1">s</span>:</b>
          <ul>
            <li class="text-danger" v-for="error in reportErrors">{{ error.message }}</li>
          </ul>
        </div>
      </b-container>

      <div slot="modal-footer" class="row align-self-end">
        <b-btn
          @click="showNewReportModal = false"
          size="md"
          class="col-0 mr-3"
          variant="outline-secondary">
          <font-awesome-icon icon="times"></font-awesome-icon> Close
        </b-btn>
        <b-btn
          @click="createReport()"
          size="md"
          class="col-0 mr-3"
          variant="primary">
          Save
        </b-btn>
      </div>

    </b-modal>

    <b-modal
      title="Are you sure you want to delete this report?"
      v-model="showAreYouSureModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      body-class="d-none">

      <div slot="modal-footer" class="row align-self-end">
        <b-btn
          size="md"
          class="mr-3"
          variant="outline-secondary"
          @click="showAreYouSureModal=false">
          <font-awesome-icon icon="times"></font-awesome-icon> No, return to reports
        </b-btn>
        <b-btn
          size="md"
          class="mr-3"
          variant="danger"
          @click="deleteReport()">
          Yes, delete this report
        </b-btn>
      </div>

    </b-modal>
  </div>

</template>

<script>
import Api from '../api.js'
import sorting from '../models/sorting'
import formats from '../models/formats'
import rowsPerPageOptions from '../models/rows-per-page-options'
import colorDefaults from '../models/color-defaults'
import enrollmentsTable from '../enrollments-table'

import {Report} from "./report-models.js"
import ReportFiltersComponent from "./report-filters"

export default {
  name: "Reports",
  components: {
    'filter-editor': ReportFiltersComponent,
    enrollmentsTable,
    sorting,
  },
  props: [
    "tab",
    "userPermissions"
  ],
  data: function() {
    return {
      // Reports user can see
      reports: [],

      // Cases user can see
      cases: [],

      // Currently selected report
      selectedReport: null,
      isEditingReport: false,

      isLoading: true,
      rowsPerPage: null,
      // Defaults for table
      tableProps: {
        sortBy: 'time',
        sortDesc: true,
        tableIsLoading: false,
        currentPage: 1,
        rowsPerPage: 25,
        totalCount: 0,
      },

      // Modals, etc
      newReportName: "",
      showNewReportModal: false,
      reportErrors: [],
      showAreYouSureModal: false,
      tableIsLoading: false,
      enrollments: [],
      colorDefaults: colorDefaults,
      isGeneratingReportDownload: false,
    };
  },
  created() {
    let rowsPerPage = parseInt(localStorage.rowsPerPage);
    this.rowsPerPage = rowsPerPage && rowsPerPageOptions.includes(rowsPerPage) ? rowsPerPage : this.tableProps.rowsPerPage;

    let reportPromise = this.searchReports();
    let casesPromise = this.fetchCases();
    Promise.all([casesPromise, reportPromise]).then(() => {
      this.isLoading = false;
    });
  },
  methods: {
    fetchCases() {
      return Api.searchCases()
      .then(response => {
        this.cases = response;
      });
    },
    searchReports() {
      return Api.searchReports()
      .then(response => {
        this.reports = response.map((r) => new Report(r));

      });
    },
    openNewReportModal() {
      this.newReportName = null;
      this.showNewReportModal = true;
    },
    createReport() {
      Api.createReport({name: this.newReportName})
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.reportErrors = response.errors;
        } else {
          this.reportErrors = [];
          this.showNewReportModal = false;
          let newReport = new Report(response);
          this.reports.push(newReport);
          this.selectedReport = newReport;
          this.isEditingReport = true;
        }
      });
    },
    handleApplyAndSave(report) {
      this.handleApply(report);
      this.saveReport(report);
    },
    handleApply(report) {
      this.searchEnrollmentReports(report);
    },
    saveReport(report) {
      this.reportErrors = [];

      // if (this.validateNumberInputs()) {
      //   this.reportErrors.push({message: 'Invalid number for Last Days.'});
      //   return;
      // }

      this.updateReportSuccess = null;

      Api.updateReport(report.serialize())
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.reportErrors = response.errors;
        } else {

          this.selectedReport.name = report.name;
          this.selectedReport.filters = report.filters;

          this.reportErrors = [];
          this.updateReportSuccess = true;
          this.isEditingReport = false;
        }
      });
    },
    handleDeleteReport() {
      this.showAreYouSureModal = true;
    },
    deleteReport() {
      this.reportErrors = [];
      Api.deleteReport(this.selectedReport.id)
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.reportErrors = response.errors;
        } else {
          // Remove the report from the list
          this.reports = this.reports.filter(r => r.id !== this.selectedReport.id);

          // Reset
          this.selectedReport = null;
          this.showAreYouSureModal = false;
        }
      });
    },
    refreshSelectedReport() {
      this.searchEnrollmentReports(this.selectedReport);
    },

    searchEnrollmentReports(report) {
      this.tableProps.tableIsLoading = true;
      this.enrollments = [];
      let data = {
        page: this.tableProps.currentPage,
        sort_column: this.tableProps.sortBy,
        sort_direction: this.tableProps.sortDesc ? 'DESC' : 'ASC',
        report_filters: report.serializeFilters(),
        rows_per_page: this.tableProps.rowsPerPage
      };

      Api.searchEnrollmentReports(data)
      .then(response => {
        this.tableProps.totalCount = response.total_count;
        this.enrollments = response.rows;

        this.tableProps.tableIsLoading = false;
      });
    },

    downloadReport() {
      let data = {
        report_filters: this.selectedReport.serializeFilters(),
        search_format: 'csv'
      };

      this.isGeneratingReportDownload = true;

      Api.startGeneratingEnrollmentReportsCsv(data)
        .then(response => {
          // @TODO this basic code to handle download generation responses is duped in three separate places.
          //   Maybe consolidate -- EF 2021-08-17
          if (response.errors && response.errors.length > 0) {
            console.error(response.errors);
            alert("Unexpected error starting export.");
          } else {
            let msg = 'Download generating. You will receive an email shortly.'
            if (response && response.msg) {
              msg = response.msg
            }
            alert(msg);
            this.isGeneratingReportDownload = false;
          }
        }).finally(() => {
        this.isGeneratingReportDownload = false;
      });
    },
  },
  computed: {
    reportOptions() {
      let options =  this.reports.map(r => {
        return {text: r.name, value: r};
      });
      formats.alphebetize(options, 'text');
      return [{"text": "(Select Report)", "value": null}].concat(options);
    }
  },
  watch: {
    selectedReport() {
      if (this.selectedReport == null) {
        return;
      }
      // Update enrollments table when the report changes.
      this.refreshSelectedReport();
    }
  }
};
</script>

<style scoped>

</style>
