<template>

  <div class="position-relative">
    <b-overlay
      :show="isAudioConferenceEnrollment && conferenceEnded && !canConcludeConference"
      no-fade
      opacity="0.9"
      variant="light">
      <template slot="overlay">
        <div class="text-center">
          <p>You or your caller unexpectedly disconnected. This {{ "enrollment" | terminology({}) }} session has
            been paused and can be re-initiated when ready to continue.</p>
          <b-button
            variant="primary"
            size="lg"
            @click="exitToEnrollmentDetails"
          >
            Ok
          </b-button>
        </div>
      </template>

<!--      <b-row>-->
<!--        <b-col>stepLinks.length: {{ this.stepLinks.length }}</b-col>-->
<!--        <b-col>currentStepIndex: {{ this.currentStepIndex }}</b-col>-->
<!--        <b-col>blockNavBeforeIdx: {{ this.blockNavBeforeIdx }}</b-col>-->
<!--        <b-col>forceHideCancelBtn: {{ this.forceHideCancelBtn }}</b-col>-->
<!--      </b-row>-->

      <div v-if="!isLoading">
        <h3 class="row">
          <b-col sm="7">
            <span v-show="siteConfig.terminologyCase === 'case'">{{
                caseName
              }} {{ "enrollment" | terminology({isTitle: true}) }}</span>
          </b-col>
          <b-col sm="5" class="text-sm-right">
            <premium-total
              v-if="shouldShowPremiumTotal"
              :applicants="applicants"
              :selectedCoverages="selectedCoverages"
              :products="products"
              :premiumMode="premiumMode"
            ></premium-total>
          </b-col>
        </h3>

        <b-card bg-variant="light">
          <div slot="header">
            <ul class="nav nav-pills nav-fill">
              <li class="nav-item" v-for="stepLink in stepLinks">
                <a class="nav-link" href="#"
                   :class="{ active: isCurrentLink(stepLink), disabled: (isFutureLink(stepLink) || restrictNavForPaymentWithAppSubmit || isBlockedLink(stepLink)) }"
                   @click="clickStepLink(stepLink)">
                  {{ stepLink.text }}
                </a>
              </li>
            </ul>
          </div>
          <p class="card-text" v-if="currentStep !== null">
            <b-form :validated="isValidating" ref="form" :novalidate="true">
              <transition name="fade" mode="out-in">
                <keep-alive>
                  <generic-step :stepTitle="currentStep.title"
                                :breadcrumb="currentStep.breadcrumb"
                                :fields="currentStep.fields"
                                :applicants="applicants"
                                :selectedCoverages="selectedCoverages"
                                :stepValidationError="stepValidationError"
                                :products="products"
                                :applicantContext="currentApplicantContext"
                                :coverageContext="currentCoverageContext"
                                :premiumMode="premiumMode"
                                :globalBeneficiaries="globalBeneficiaries"
                                :paymentInformation="paymentInformation"
                                :siteConfig="siteConfig"
                                @enrollment-data-changed="handleEnrollmentDataChanged"
                                @applicant-data-changed="handleApplicantDataChanged"
                                @coverage-data-changed="handleCoverageDataChanged"
                                @emp-remote-signature-toggled="handleRemoteSignatureSsnValidation"

                  ></generic-step>
                </keep-alive>
              </transition>
            </b-form>
          </p>
          <div slot="footer">

            <b-button v-if="shouldShowDeclineBtn" :disabled="restrictNavForPaymentWithAppSubmit" size="sm"
                      class="mt-2 align-middle"
                      @click="declineEnrollment">Decline
            </b-button>
            <b-button v-if="shouldShowCancelBtn" :disabled="restrictNavForPaymentWithAppSubmit" size="sm"
                      class="mt-2 align-middle"
                      @click="cancelEnrollment">Cancel
            </b-button>
            <b-button v-if="shouldShowPauseBtn" size="sm" class="mt-2 ml-3 align-middle" variant="warning"
                      @click="pauseEnrollment">
              <font-awesome-icon icon="pause-circle"></font-awesome-icon>
              Pause
            </b-button>

            <div class="float-right">
              <b-spinner v-show="isNavigating" variant="secondary" small class="align-middle mr-3"></b-spinner>

              <template v-if="isAudioConferenceEnrollment">
                <b-btn v-if="isConferenceEnded" variant="outline-secondary" class="mx-4" disabled size="sm">
                  <font-awesome-icon icon="phone"></font-awesome-icon>
                  <span>Conference Ended.</span>
                </b-btn>
                <b-btn v-if="isConferenceStarting" variant="outline-warning" class="mx-4" disabled size="sm">
                  <font-awesome-icon icon="phone"></font-awesome-icon>
                  <span v-text="conferenceStatusLabel"></span>
                </b-btn>
                <b-dropdown v-if="isConferenceInProgress" class="mx-4" size="sm"
                            :disabled="isNavigating || isUpdatingConference"
                            :variant="`outline-${conferenceStatusVariant}`"
                            v-b-popover.hover.top="`${conferenceStatusLabel}`">

                  <template slot="button-content">
                    <font-awesome-icon icon="phone"></font-awesome-icon>
                    <b-spinner type="grow" small :variant="conferenceStatusVariant"
                               :label="conferenceStatus"></b-spinner>
                    <span v-if="isUpdatingConference">Updating Conference</span>
                    <span v-else v-text="conferenceStatusLabel"></span>
                  </template>

                  <b-dropdown-group>
                    <b-dropdown-header>Play on current Audio {{
                        "enrollment" | terminology({isTitle: true})
                      }}:
                    </b-dropdown-header>
                    <b-dropdown-item-button v-for="(scriptLabel, scriptId) in autoReadScripts"
                                            @click="sayScriptOnConference(scriptId)" :key="scriptId">
                      <font-awesome-icon icon="play-circle"></font-awesome-icon>
                      {{ scriptLabel }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="sayScriptOnConference('__cancel__')" key="__cancel__">
                      <font-awesome-icon icon="times"></font-awesome-icon>
                      Cancel Reading
                    </b-dropdown-item-button>
                  </b-dropdown-group>
                </b-dropdown>
              </template>


              <b-button size="sm" variant="secondary" class="align-middle mr-3"
                        v-show="hasPreviousStep" @click="goToPreviousStep" :disabled="stepNavigationDisabled">
                <font-awesome-icon icon="chevron-left"></font-awesome-icon>
                Previous
              </b-button>
              <b-button size="lg" variant="primary" class=" align-middle"
                        v-show="hasNextStep" @click="handleNextButton" :disabled="nextStepDisabled">Next
                <font-awesome-icon icon="chevron-right"></font-awesome-icon>
              </b-button>
              <b-button size="lg" variant="success" class=" align-middle"
                        :disabled="isSubmissionModalShowing || (!canSubmit && !forceAllowSubmit)"
                        v-show="isLastStep" @click="validateAndSubmitApplication">{{ submitButtonText }}
              </b-button>
              <b-button size="lg" variant="danger" class=" align-middle"
                        v-show="isStopStep" @click="submitApplication">Decline all coverage
                <font-awesome-icon icon="times-circle"></font-awesome-icon>
              </b-button>


            </div>

          </div>
        </b-card>


        <b-modal v-model="isSubmissionModalShowing"
                 :title="'Submitting ' + terminology.formatEnrollment({isTitle: true})"
                 no-close-on-esc no-close-on-backdrop hide-header-close hide-footer>
          <div v-show="submissionProblem === ''">
            <b-progress :value="100" variant="success" :striped="true" class="mb-2"></b-progress>
            <b-row>
              <b-col>Submitting {{ 'enrollment' | terminology({isTitle: true}) }}, please wait...</b-col>
            </b-row>
          </div>
          <b-row>
            <b-col class="text-error">
              {{ submissionProblem }}
            </b-col>
          </b-row>
        </b-modal>

        <b-modal v-model="isCancelModalShowing"
                 :title="'Cancel' + terminology.formatEnrollment({isTitle: true}) + ' Session'"
                 size="lg"
                 @hide="handleCancelModalDismissed"
        >
          <div>
            <b-row>
              <b-col>Are you sure you want to cancel this {{ 'enrollment' | terminology({}) }}? All data will be
                discarded.
              </b-col>
            </b-row>
          </div>
          <div slot="modal-footer" class="align-self-end">
            <b-row>
              <b-btn
                @click="handleCancelModalDismissed('hide')"
                size="md"
                class="col-0 mr-3"
                variant="outline-secondary">
                <font-awesome-icon icon="times"></font-awesome-icon>
                Cancel
              </b-btn>
              <b-btn
                @click="handleEnrollmentPaused"
                size="md"
                v-if="shouldShowPauseBtn"
                class="col-0 mr-3"
                variant="warning">
                <font-awesome-icon icon="pause-circle"></font-awesome-icon>
                Save and Resume later
              </b-btn>
              <b-btn
                @click="handleEnrollmentCanceled"
                size="md"
                class="col-0 mr-3"
                variant="danger"
              >
                <font-awesome-icon icon="trash"></font-awesome-icon>
                Discard data and Exit
              </b-btn>

            </b-row>
          </div>
        </b-modal>

        <b-modal v-model="isDeclineModalShowing"
                 :title="'Decline '+ terminology.formatEnrollment({isTitle: true, isPlural: true})"
                 cancel-title="Yes, Decline"
                 cancel-variant="danger"
                 ok-title="No, Go Back"
                 ok-variant="primary"
                 @cancel="handleEnrollmentDeclined">
          <div>
            <b-row>
              <b-col>You are about to cancel this application and waive coverage of the presented benefits. Please be
                aware that once declined you will need to contact your benefit representative to re-initiate any new
                enrollment.
              </b-col>
            </b-row>
          </div>
        </b-modal>

        <b-modal v-model="isPauseModalShowing"
                 :title="'Pause '+ terminology.formatEnrollment({isTitle: true})"
                 ok-title="Yes, Pause"
                 ok-variant="warning"
                 cancel-title="No, Go Back"
                 cancel-variant="default"
                 @ok="handleEnrollmentPaused">
          <div>
            <b-row>
              <b-col>You are about to pause this {{ 'enrollment' | terminology({}) }}.</b-col>
            </b-row>
          </div>
        </b-modal>


      </div>
      <loading-spinner v-else size="large"></loading-spinner>

    </b-overlay>
  </div>

</template>

<script>

import GenericStep from "./StepComponent"

import {
  Applicant,
  ApplicantList,
  ApplicantTypeChild,
  ApplicantTypeEmployee,
  ApplicantTypeSpouse,
  CoverageList,
  getPremiumModeByType,
  Product,
  ProductList
} from "./models";
import {siteConfig, terminology} from "../app";
import {debounce} from '../utils'

import Api from '../api';

import bus from './messages'
import moment from "../vendor/moment-timezone-with-data-1970-2030.js";
import PremiumTotal from "./PremiumTotal";
import EnrollmentConferenceSessions from "../models/enrollment-conference-sessions";

// Since this component is often used recursively, it is easier to register it globally to avoid
//  recursive imports in JS.
import EnrollmentFieldSet from "./EnrollmentFieldSet";
import Vue from "vue";

Vue.component("enrollment-field-set", EnrollmentFieldSet);

// Load and bootstrap PDFjs library
//  Using legacy (ES5) build to support older browsers (IE11)
const pdfjs = require("../../node_modules/pdfjs-dist/legacy/build/pdf.js");
const PdfjsWorker = require("worker-loader?esModule=false&filename=[name].js!../../node_modules/pdfjs-dist/legacy/build/pdf.worker.js");
if (typeof window !== "undefined" && "Worker" in window) {
  pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker();
}


const API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH;

class StepData {
  constructor(data) {
    this.data = data;
  }

  getApplicantContext(applicants) {
    if (this.data.contextType === 'applicant' && this.data.applicantContextType) {
      let app = applicants.getFirstApplicantByType(this.data.applicantContextType);
      if (app === undefined) {
        app = new Applicant({type: this.data.applicantContextType});

        // Default new applicant last name to Employee last name
        let emp_last_name = applicants.getFirstApplicantByType(ApplicantTypeEmployee).getLastName();
        if (emp_last_name) {
          app.updateData("last_name", emp_last_name);
        }
        applicants.addApplicant(app);
      }
      return app;

    } else if (this.data.contextType === 'applicant' && this.data.applicantId >= 0) {
      return applicants.getApplicantById(this.data.applicantId);
    }
    return null
  }

  getCoverageContext(coverages) {
    if (this.data.contextType === 'coverage') {
      // FIXME zach 2/4/22 This invocation is wrong, it clearly never gets here
      //  there have not been any steps with a single coverage context so far, usually the context is
      //  given directly in the data or it is an applicant context step.
      return coverages.getOrCreateCoverageForApplicantProduct(this.data.coverageProduct);
    }
  }
}


let wizard = {
  name: "WizardComponent",
  components: {
    'generic-step': GenericStep,
    'premium-total': PremiumTotal,
  },
  data: function () {
    let employee = new Applicant({type: ApplicantTypeEmployee});

    return {
      isLoading: true,
      initialData: {},
      premiumMode: null,

      isSelfEnroll: false,
      isRemoteSignature: false,
      isAgentEnrollRemoteSignature: false,
      isCallCenterReview: false,

      isValidating: false,
      isNavigating: false,
      isUpdatingConference: false,
      caseName: null,
      applicants: new ApplicantList([employee]),

      steps: [],
      products: new ProductList(),
      currentStepIndex: null,
      currentApplicantContext: null,
      currentCoverageContext: null,
      empSelectedRemoteSignature: null,

      isSubmissionModalShowing: false,
      submissionProblem: "",
      isCancelModalShowing: false,
      isPauseModalShowing: false,
      isDeclineModalShowing: false,
      submitButtonText: null,

      rates: null,
      selectedCoverages: new CoverageList(),

      stepValidationError: null,

      globalBeneficiaries: [],

      terminology: terminology,
      siteConfig: siteConfig,

      // @TODO mark this false when cancelling a conference
      isAudioConferenceEnrollment: false,
      conferenceStatus: null,
      conferenceStatusReason: null,
      conferenceEnded: null,

      conferenceStatusMap: EnrollmentConferenceSessions.conferenceStatusMap,

      isConferenceCancelling: false,
      isConferenceCancelled: false,

      _confStatusSubTimeout: null,

      autoReadScripts: [],

      paymentInformation: {
        paymentMethodId: null,
        paymentCustomerId: null,
        paymentScheduleType: 'monthly-day-of-month', // or 'monthly-day-of-week'
        paymentScheduleDayOfMonth: 1, // should be 1-28
        paymentScheduleDayOfWeek: 1, // 0-6
        paymentScheduleWeekdayOccurrence: 1, // 1-4
        paymentWithApp: false,
        paymentWithAppMade: false,
      },

      forceStepNavigationDisabled: false,
      forceNextStepDisabled: false,
      forceAllowSubmit: false,
      forceHideCancelBtn: false,
      blockNavBeforeIdx: 0
    }
  },
  computed: {

    canSubmit() {
      if (this.selectedCoverages && this.paymentInformation.paymentWithApp && !this.paymentInformation.paymentWithAppMade) {
        return false;
      }
      return true;
    },

    restrictNavForPaymentWithAppSubmit() {
      if (this.paymentInformation.paymentWithApp && this.paymentInformation.paymentWithAppMade && this.isLastStep) {
        return true;
      }
      return false;
    },

    stepNavigationDisabled() {
      if (!!this.forceStepNavigationDisabled) {
        return true;
      }

      if (this.restrictNavForPaymentWithAppSubmit) {
        return true;
      }

      return !!this.isNavigating;
    },

    nextStepDisabled() {

      if (!!this.stepNavigationDisabled) {
        return true;
      }

      if (!!this.forceNextStepDisabled) {
        return true;
      }

      return !!this.isNavigating;
    },
    stepLinks() {
      // group steps by step.breadcrumb
      let groupedSteps = {};
      this.steps.forEach((step, idx) => {
        console.debug("stepLinks: idx, step:", idx, step);
        if (step.breadcrumb) {
          groupedSteps[step.breadcrumb] = {
            text: step.breadcrumb,
            _origIdx: idx
          };
          console.debug("addded step to groupedSteps:", step.breadcrumb, groupedSteps[step.breadcrumb]);
        }
      });

      return Object.values(groupedSteps);
    },

    hasPreviousStep() {
      if (this.blockNavBeforeIdx) {
        return this.currentStepIndex > this.blockNavBeforeIdx;
      }
      return this.currentStepIndex > 0;
    },
    isStopStep() {
      return this.steps[this.currentStepIndex].is_terminal_step;
    },
    hasNextStep() {
      return this.currentStepIndex < this.steps.length - 1 && !this.isStopStep;
    },
    isLastStep() {
      return this.hasNextStep === false && !this.isStopStep;
    },
    currentStep() {
      if (this.currentStepIndex === null) {
        return null;
      } else if (this.currentStepIndex >= this.steps.length) {
        return null;
      }

      return this.steps[this.currentStepIndex];
    },

    firstTerminalStepIndex() {
      return this.steps.findIndex(step => step.is_terminal_step);
    },

    // submitButtonText() {
    //   if (this.isRemoteSignature) {
    //     return "Sign Application";
    //   } else if (this.isAgentEnrollRemoteSignature) {
    //     return "Finish and Send for Signature";
    //   } else if (this.isCallCenterReview) {
    //     return "Finish and Close"
    //   }
    //   return "Finish and Submit Application";
    // },
    shouldShowPremiumTotal() {
      return !this.isRemoteSignature && !this.isCallCenterReview
    },
    shouldShowDeclineBtn() {
      return this.isRemoteSignature;
    },
    shouldShowCancelBtn() {
      return !this.isRemoteSignature && !this.isCallCenterReview && !this.forceHideCancelBtn;
    },
    shouldShowPauseBtn() {
      return !this.isRemoteSignature && !this.isCallCenterReview && !this.isSelfEnroll;
    },

    // @TODO duplicate code from new-enrollment-modal.vue. Merge this into something reusable
    isConferenceReadyToStart() {
      return EnrollmentConferenceSessions.helpers.isConferenceReadyToStart(this.conferenceStatus);
    },
    isConferenceStarting() {
      return EnrollmentConferenceSessions.helpers.isConferenceStarting(this.conferenceStatus);
    },
    isConferenceInitializing() {
      return EnrollmentConferenceSessions.helpers.isConferenceInitializing(this.conferenceStatus);
    },
    isConferenceInProgress() {
      return EnrollmentConferenceSessions.helpers.isConferenceInProgress(this.conferenceStatus);
    },
    isConferenceEnded() {
      return EnrollmentConferenceSessions.helpers.isConferenceEnded(this.conferenceStatus);
    },
    isConferenceEndedUnexpectedly() {
      return EnrollmentConferenceSessions.helpers.isConferenceEndedUnexpectedly(this.conferenceStatus);
    },
    canConcludeConference() {
      // @TODO is this when we can conclude?
      return this.isLastStep;
    },
    conferenceStatusLabel() {
      return EnrollmentConferenceSessions.helpers.label(this.conferenceStatus);
    },
    conferenceStatusVariant() {
      return EnrollmentConferenceSessions.helpers.variant(this.conferenceStatus);
    },
  },
  created() {
    // Hide nav
    bus.$emit("hide-navigation");

    Api.searchAutoReadScripts()
      .then((r) => {
        if (r && !r.errors) {
          this.autoReadScripts = r;
        }
      });

    // Load initial data
    fetch(API_BASE_PATH + "/api/wizard/session", {method: 'GET', credentials: "include"})
      .then(response => response.json())
      .then(async json => {
        this.initialData = json;

        if (this.initialData.enrollment_session_conference_name) {
          // this will trigger the watch on isAudioConferenceEnrollment
          this.isAudioConferenceEnrollment = true;
        }

        if (this.initialData.case === undefined) {
          // Not a valid session, return to enrollments page
          this.redirectToEnrollmentCompletePage();
        }
        if (this.initialData.case.premium_mode === undefined) {
          this.premiumMode = getPremiumModeByType("monthly");
        } else {
          this.premiumMode = getPremiumModeByType(this.initialData.case.premium_mode);
        }

        this.empSelectedRemoteSignature = !!this.initialData.case.force_agent_enroll_remote_sig;

        // check if self-enroll and if remote-sig
        if (this.initialData.isSelfEnroll === true) {
          this.isSelfEnroll = true
        }
        if (this.initialData.isRemoteSignature === true) {
          this.isRemoteSignature = true
        }
        if (this.initialData.isCallCenterReview === true) {
          this.isCallCenterReview = true
        }
        if (this.initialData.isAgentEnrollRemoteSignature === true) {
          this.isAgentEnrollRemoteSignature = true
        }

        this.products = new ProductList(this.initialData['products'].map((p) => new Product(p)));

        // Load initial data from session
        let emp = this.applicants.getFirstApplicantByType(ApplicantTypeEmployee);

        // If resuming, first load in resumed data
        if (this.initialData.resumed_enrollment_data) {
          emp.mergeData(this.initialData.resumed_enrollment_data.data.applicants[0]);
        }

        // Load in any updated census data
        // TODO: do not merge in every key here, just the keys that pertain to employee.
        emp.mergeData(this.initialData);

        // Handle city and state separately due to naming conflict with enrollment city state.
        if (this.initialData.applicant_city) {
          emp.mergeData({city: this.initialData.applicant_city});
        }
        if (this.initialData.applicant_state) {
          emp.mergeData({state: this.initialData.applicant_state});
        }

        // If resuming, load in applicants first from saved enrollment data.
        if (this.initialData.resumed_enrollment_data) {
          let addedByType = new Map();
          this.initialData.resumed_enrollment_data.data.applicants.forEach((applicantData) => {
            if (applicantData.type === ApplicantTypeSpouse || applicantData.type === ApplicantTypeChild) {
              let applicant = new Applicant({
                id: applicantData.id,
                type: applicantData.type,
              });
              applicant.mergeData(applicantData);
              this.applicants.addApplicant(applicant);
            }
          });

          // Load in coverage data from saved enrollment.
          if (this.initialData.resumed_enrollment_data.data.selectedCoverages) {
            this.initialData.resumed_enrollment_data.data.selectedCoverages.forEach(coverageData => {
              let applicant = this.applicants.getApplicantById(coverageData.applicant);
              this.selectedCoverages.addCoverageFromSavedData(coverageData, applicant, this.products.getById(coverageData.product.id));
            })
          }

        } else {
          // Not resuming - but include any info from other enrollments (spouse, children)
          if (this.initialData.spouse) {
            let sp = new Applicant({
              type: ApplicantTypeSpouse,
            });
            sp.mergeData(this.initialData.spouse);
            this.applicants.addApplicant(sp);
          }
          if (this.initialData.children) {
            for (let childIndex in this.initialData.children) {
              let ch = new Applicant({
                type: ApplicantTypeChild,
              });
              ch.mergeData(this.initialData.children[childIndex]);
              this.applicants.addApplicant(ch);
            }
          }
        }


        this.caseName = this.initialData.case.group_name;

      }).catch((e) => {
      console.error("error fetching enrollment package", e);
    }).then(() => {
      // Fetch initial step (setCurrentStep always refreshes the step data, so this also populates the initial steps).
      this.setCurrentStep(0).then(() => {

        // Advance to saved step if resuming enrollment.
        const resumeStepIndex = this.getResumeStepIndexIfAllowed();
        if (resumeStepIndex > 0) {
          this._setStep(resumeStepIndex);
        }

        // Update rates to trigger premium lookup for any previously selected coverages.
        this.handleRatesShouldChange();

        this.isLoading = false;
      })
    }).catch(this.handleFetchError());

    // Listen to events from sub-components.
    bus.$on("review-documents", this.previewEnrollment);
    bus.$on("send-call-center-review-email", this.sendCallCenterReviewEmail);
    bus.$on("submit-application", this.submitApplication);

    bus.$on("applicant-data-changed", this.handleApplicantDataChanged);
    bus.$on("coverage-data-changed", this.handleCoverageDataChanged);
    bus.$on("enrollment-data-changed", this.handleEnrollmentDataChanged);

    bus.$on("update-premium", this.handleRatesShouldChange);
    bus.$on("reload-current-step", this.reloadCurrentStep);

    bus.$on("add-child", this.handleAddChild);
    bus.$on("remove-child", this.handleRemoveChild);

    bus.$on("beneficiary-added", (beneficiary) => {
      // search for a matching beneficiary on the globalBeneficiaries list and update it if found
      // otherwise, add it to the list
      if (beneficiary.name === "" || beneficiary.relationship === "") {
        console.debug("Beneficiary name or relationship is empty, not adding to global list.", beneficiary);
        return;
      }
      
      let existingIndex = this.globalBeneficiaries.map(b => b.name).indexOf(beneficiary.name);
      if (existingIndex >= 0) {
        this.globalBeneficiaries.splice(existingIndex, 1, beneficiary);
      } else {
        this.globalBeneficiaries.push(beneficiary);
      }
    });

    // events from the Enrollment Session Conference
    bus.$on("conference-status-updated", (data) => {
      console.debug("Received conference-status-updated data:", data);

      this.conferenceStatus = data['status'];
      this.conferenceStatusReason = data['status_reason'];
      this.conferenceEnded = data['ended'];
    });

    //
    bus.$on("payment-information-updated", (data) => {
      console.debug("Received payment-information-updated data:", data);
      this.paymentInformation.paymentMethodId = data.paymentMethodId;
      this.paymentInformation.paymentCustomerId = data.paymentCustomerId;
      this.paymentInformation.paymentScheduleType = data.paymentScheduleType;
      this.paymentInformation.paymentScheduleDayOfMonth = data.paymentScheduleDayOfMonth;
      this.paymentInformation.paymentScheduleDayOfWeek = data.paymentScheduleDayOfWeek;
      this.paymentInformation.paymentScheduleWeekdayOccurrence = data.paymentScheduleWeekdayOccurrence;
      this.paymentInformation.paymentWithApp = data.paymentWithApp;
      this.paymentInformation.paymentWithAppMade = data.paymentWithAppMade;
    });

    bus.$on("update-step-navigation-disabled", (disabled) => {
      console.debug("Received update-step-navigation-disabled:", disabled);
      this.forceStepNavigationDisabled = !!disabled;
    });

    bus.$on("update-force-next-step-disabled", (disabled) => {
      console.debug("update-force-next-step-disabled:", disabled);
      this.forceNextStepDisabled = !!disabled;
    });

    bus.$on("play-audio-announcement", (params) => {
      console.debug("received play-audio-announcement:", params);
      if (params && params.scriptId) {
        this.sayScriptOnConference(params.scriptId);
      }
    })


  },
  methods: {

    handleRemoteSignatureSsnValidation(isChecked) {
      const updatedFields = this.steps[this.currentStepIndex].fields.reduce((fields, dataField) => {
        if (dataField.name === 'emp_last_four_ssn_confirm') {
          fields = isChecked ? [...fields, { ...dataField, optional: true }] : [...fields, { ...dataField, optional: false }];
        } else {
          fields = [...fields, { ...dataField }];
        }
        return fields;
      }, []);
      this.steps[this.currentStepIndex].fields = [...updatedFields];
    },

    updateSteps() {
      return fetch(API_BASE_PATH + "/api/wizard/update-steps",
        {
          method: 'POST',
          credentials: "include",
          headers: {'content-type': 'application/json'},
          body: JSON.stringify(this.serializeEnrollmentData()),
        }).then((resp) => {
        return resp.json();
      }).catch(this.handleFetchError());
    },

    refreshSteps() {
      return this.updateSteps().then((resp) => {
        this.steps = resp.steps;
        if (this.steps && resp.current_step) {
          console.debug('refreshSteps: current step:', resp.current_step);
          this._setStep(resp.current_step);
        }
        this.updateUIForUnderwritingState(resp)
      });
    },

    updateUIForUnderwritingState(updateStepsResponse) {
      if (!!siteConfig.ffEnableExternalUnderwriting) {
        this.forceAllowSubmit = !!updateStepsResponse.force_allow_submit;
        this.forceHideCancelBtn = !!updateStepsResponse.is_uw_submitted;

        if (!!updateStepsResponse.is_uw_submitted) {
          this.lockStepsUpToApplicantSign();
        } else {
          this.blockNavBeforeIdx = 0;
        }
      }
    },

    /**
     *
     * @returns {number} -1 if the step is not found. Else the lastApplicantSignStepIndex that was found
     */
    lockStepsUpToApplicantSign() {
      // get a copy of all steps
      let steps = this.steps.slice(0);
      // find the last step index with the name 'applicant_signature'
      let lastApplicantSignStepIndex = steps.findIndex(step => step.name === 'applicant_signature');
      if (lastApplicantSignStepIndex > 0) {
        this.blockNavBeforeIdx = lastApplicantSignStepIndex + 1;
      }
      console.debug('lockStepsUpToApplicantSign: lastApplicantSignStepIndex:', lastApplicantSignStepIndex);
      return lastApplicantSignStepIndex;
    },

    async setCurrentStep(stepIndex) {
      // clear the blocks on navigation
      this.forceNextStepDisabled = false;
      this.forceStepNavigationDisabled = false;

      await this.refreshSteps(); // this will update this.steps directly

      if (this.blockNavBeforeIdx > 0 && stepIndex < this.blockNavBeforeIdx) {
        console.debug(`setCurrentStep: stepIndex ${stepIndex} is less than blockNavBeforeIdx ${this.blockNavBeforeIdx}' - setting stepIndex to ${this.blockNavBeforeIdx}`);
        stepIndex = this.blockNavBeforeIdx;
      }

      this._setStep(stepIndex);
    },

    _setStep(stepIndex) {
      let step = this.steps[stepIndex];
      this.currentStepIndex = stepIndex;
      this.currentApplicantContext = new StepData(step).getApplicantContext(this.applicants);
      this.currentCoverageContext = new StepData(step).getCoverageContext(this.selectedCoverages);
      this.stepValidationError = "";
    },

    async clickStepLink(stepLink) {
      if (this.isCurrentLink(stepLink) || this.isFutureLink(stepLink)) {
        // don't do anything if we click the current or a future step link.
        return;
      }

      let index = 0;
      for (let step of this.steps) {
        if (step.breadcrumb === stepLink.text) {
          await this.setCurrentStep(index);
          break;
        }
        index += 1;
      }
    },
    isCurrentLink(stepLink) {
      if (!this.currentStep) {
        return false;
      }
      return this.currentStep.breadcrumb === stepLink.text;
    },
    isBlockedLink(stepLink) {
      return stepLink._origIdx < this.blockNavBeforeIdx;
    },
    isFutureLink(stepLink) {
      let index = 0;
      for (let step of this.steps) {
        if (step.breadcrumb === stepLink.text)
        {
          console.debug("matching step:", step.name, "for stepLink:", stepLink.text);
          console.debug('isFutureLink: Breadcrumb:', step.breadcrumb, 'index:', index, 'currentStepIndex:', this.currentStepIndex);
          // Short circuit and return if we have passed the current link to prevent skipping ahead.
          if (index > this.currentStepIndex) {
            console.debug("isFutureLink: true because index > this.currentStepIndex");
            return true;
          }
          // We can't link past terminal steps
          if (this.firstTerminalStepIndex > -1 && stepLink._origIdx > this.firstTerminalStepIndex) {
            console.debug("isFutureLink: true because of terminal step");
            return true;
          }
        }

        index += 1;
      }

      return false;
    },

    goToPreviousStep: debounce(async function () {
      this.isNavigating = true;
      let stepIndex = this.currentStepIndex;
      if (stepIndex >= 1) {
        await this.setCurrentStep(stepIndex - 1);
        this.scrollToTop();
      }
      this.isNavigating = false;
    }, 1000, true),

    handleNextButton: debounce(async function () {
      this.isNavigating = true;
      let valid = await this.performStepValidation();
      if (valid) {
        await this.goToNextStep();
      }
      this.isNavigating = false;
    }, 1000, true),

    goToNextStep: debounce(async function () {

      let stepIndex = this.currentStepIndex;
      if (stepIndex < this.steps.length - 1) {

        await this.setCurrentStep(stepIndex + 1);
        this.scrollToTop();
      }
    }, 1000, true),

    reloadCurrentStep: async function () {
      this.isNavigating = true;
      await this.setCurrentStep(this.currentStepIndex);
      this.scrollToTop();
      this.isNavigating = false;
    },

    async validateStepServerSide() {
      return fetch(API_BASE_PATH + "/api/wizard/validate-step", {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify({data: this.serializeEnrollmentData(), current_step: this.currentStepIndex}),
        credentials: "include",
      }).then((r) => {
        return r.json()
      }).catch(this.handleFetchError());
    },

    validateStep() {
      this.isValidating = true;
      bus.$emit("validate");
      return this.$refs.form.checkValidity();
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    handleEnrollmentDataChanged({step, name, value}) {
      console.debug("Enrollment data changed", step, name, value);
    },
    handleCoverageDataChanged({coverage, name, value}) {
      console.debug("Coverage data changed", coverage, name, value);
      coverage.updateData(name, value);
    },
    handleApplicantDataChanged({applicant, name, value}) {
      console.debug("Applicant data changed", applicant, name, value);
      applicant.updateData(name, value);
      this.empSelectedRemoteSignature = applicant.getEmpSelectedRemoteSignature();
    },

    handleAddChild() {
      let emp_last_name = this.applicants.getFirstApplicantByType(ApplicantTypeEmployee).getLastName();
      let child = new Applicant({type: ApplicantTypeChild});
      if (emp_last_name) {
        child.updateData("last_name", emp_last_name);
      }
      this.applicants.addApplicant(child);
    },
    handleRemoveChild(child) {
      this.applicants.removeApplicant(child);
      // Ensure we remove any coverages associated with this applicant.
      this.selectedCoverages.removeApplicantCoverages(child);
    },

    handleRatesShouldChange() {

      this.fetchRates().then((rates) => {

        this.rates = rates;


        bus.$emit("updated-rates", rates);
      });
    },
    coverageSelectionComplete(val) {
      this.selectedCoverage = val.value;
    },
    handleQuestionAnswerChanged(data) {
      this[data.name] = data.value;
    },

    async sendCallCenterReviewEmail() {
      const EMAIL_SEND_COMPLETE_EVENT = "email-send-complete";
      try {
        const resp = await fetch(API_BASE_PATH + "/api/wizard/send-call-center-review-email", {
          method: 'POST',
          headers: {'content-type': 'application/json'},
          body: JSON.stringify(this.serializeEnrollmentData()),
          credentials: "include",
        });
        if (resp.status === 200) {
          let data = await resp.json();
          // Store the token in the enrollment data so the backend can update the existing token rather than
          //  create a new one if the agent re-sends the email.
          this.initialData.call_center_review_token = data.token;
          // Tell the button widget that the send succeeded.
          bus.$emit(EMAIL_SEND_COMPLETE_EVENT, true);
        } else {
          bus.$emit(EMAIL_SEND_COMPLETE_EVENT, false);
        }
      } catch (e) {
        bus.$emit(EMAIL_SEND_COMPLETE_EVENT, false);
      }
    },

    previewEnrollment({summaryOnly}) {

      let req = this.fetchEnrollmentPreview(summaryOnly).then((resp) => {
        if (resp.status !== 200) {
          throw("Invalid PDF Bytes");
        }
        return resp.arrayBuffer();
      }).catch(this.handleFetchError("There was a server problem creating the preview PDF")
      ).then((pdfBytes) => {

        pdfjs.getDocument(pdfBytes).promise.then((pdf) => {
          let canvasContainer = document.getElementById("enrollment-preview");
          let options = {
            scale: 1.0,

          };

          function renderPage(page) {
            let viewport = page.getViewport(options);
            let canvas = document.createElement('canvas');

            let ctx = canvas.getContext('2d');
            let renderContext = {
              canvasContext: ctx,
              viewport: viewport
            };

            canvas.height = viewport.height;
            canvas.width = viewport.width;
            canvasContainer.appendChild(canvas);

            return page.render(renderContext);
          }

          for (let i = 1; i <= pdf.numPages; i += 1) {
            pdf.getPage(i).then(renderPage);
          }
          bus.$emit("preview-ready");
        });

      });

    },
    fetchEnrollmentPreview(summaryOnly) {
      let body = '';
      if (this.selectedCoverages.serialize().length > 0) {
        body = JSON.stringify(this.serializeEnrollmentData());
      }

      let url = (summaryOnly) ? "/api/wizard/preview-summary" : "/api/wizard/preview";

      return fetch(API_BASE_PATH + url, {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: body,
        credentials: "include",
      }).catch(this.handleFetchError("There was a server problem previewing the enrollment"));
    },

    fetchRates() {
      return fetch(API_BASE_PATH + "/api/wizard/rates", {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(this.serializeEnrollmentData()),
        credentials: "include",
      }).then((r) => {
        return r.json()
      }).catch(this.handleFetchError('There was a server problem fetching premium information'));
    },

    cancelEnrollment() {
      this.isCancelModalShowing = true;
    },

    pauseEnrollment() {
      this.isPauseModalShowing = true;
    },

    handleCancelModalDismissed(trigger) {
      this.isCancelModalShowing = false;
    },

    /**
     *
     * @param {String} toSay
     */
    sayOnConference(toSay) {
      this.isUpdatingConference = true;
      Api.sayOnCurrentConference(toSay)
        .then((r) => {
          console.log('said', r);
        }).finally(() => {
        this.isUpdatingConference = false;
      });
    },


    /**
     *
     * @param {String} scriptId
     */
    sayScriptOnConference(scriptId) {
      this.isUpdatingConference = true;
      Api.sayScriptOnCurrentConference(scriptId)
        .then((r) => {
          console.log('said script', r);
        }).finally(() => {
        this.isUpdatingConference = false;
      });
    },

    handleEnrollmentCanceled() {
      // first, submit cancel to an endpoint
      fetch(API_BASE_PATH + "/api/wizard/cancel", {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(this.serializeEnrollmentData()),
        credentials: "include",
      })
        // then, redirect
        .then(r => {
          this.redirectToEnrollmentCompletePage();
        })
    },

    declineEnrollment() {
      this.isDeclineModalShowing = true;
    },

    handleEnrollmentDeclined() {
      // first, submit decline to an endpoint
      fetch(API_BASE_PATH + "/api/wizard/decline", {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(this.serializeEnrollmentData()),
        credentials: "include",
      })
        // then, redirect
        .then(r => {
          this.redirectToEnrollmentCompletePage();
        })
    },

    handleEnrollmentPaused() {
      // pause the enrollment then redirect
      this._pauseEnrollment()
        // then, redirect
        .then(r => {
          this.redirectToEnrollmentCompletePage();
        });
    },

    _pauseEnrollment() {
      return fetch(API_BASE_PATH + "/api/wizard/pause", {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(this.serializeEnrollmentData()),
        credentials: "include",
      }).catch(this.handleFetchError())

    },

    async performStepValidation() {
      // Clear out error before validation
      this.stepValidationError = "";

      // Validate built-in html form conditions.
      if (!this.validateStep()) {
        this.stepValidationError = "Please complete all required information above";
        return Promise.resolve(false);
      }
      this.isValidating = false;

      // Server-side validation
      let validation = await this.validateStepServerSide();
      if (!validation.is_valid) {
        let error = validation.errors[0];
        if (error.name === null) {
          this.stepValidationError = error.message;
        } else {
          // TODO: Highlight faulty field identified by name
          this.stepValidationError = error.message;
        }
        return Promise.resolve(false);
      }
      return Promise.resolve(true);
    },

    validateAndSubmitApplication: debounce(async function () {
      let valid = await this.performStepValidation();
      if (valid) {
        this.submitApplication()
      }
    }, 3000, true),

    async submitAgentSplit(){
      console.log("in agent split submit")
      return fetch(API_BASE_PATH + "/api/wizard/update-enrollment-agent-split", {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify({data: this.serializeEnrollmentData(), current_step: this.currentStepIndex}),
        credentials: "same-origin",
      }).then(r => {
        if (r.status === 200) {
          console.log("success")
        }
      })
    },
    async submitApplication() {
      if (this.isSubmissionModalShowing === true) {
        // protect against double click
        return;
      }

      if (this.isCallCenterReview) {
        // Redirect to complete page, there is no submission for this mode.
        this.$router.push({name: 'WizardComplete'});
        return;
      }

      this.isSubmissionModalShowing = true;
      this.submissionProblem = "";

      try {
        const response = await fetch(API_BASE_PATH + "/api/wizard/submit", {
          method: 'POST',
          headers: {'content-type': 'application/json'},
          body: JSON.stringify(this.serializeEnrollmentData()),
          credentials: "include",
        });

        if (response.status === 200) {
          delete localStorage.expireToken;
          delete localStorage.payment_information;
          delete localStorage.update_payment;
          delete localStorage.first_time_update;

          const json = await response.json();

          if (json.success) {
            this.submitAgentSplit();
            window.setTimeout(() => {
              this.redirectToEnrollmentCompletePage();
            }, 3000);
          } else {
            this.submissionProblem = "There was a problem processing the submission.";
          }
        } else {
          localStorage.setItem('expireToken', JSON.stringify({ status: true }));
          localStorage.setItem('new_token_expired', JSON.stringify({ status: true }));
          if (response.status === 408) {
            alert("We’re sorry, but the 10-minute authorization for your payment information has expired. You will be taken to re-enter the payment details and then you may complete this application session.");
          } else {
            alert("We’re sorry, but there is an unknown error with the entered payment information. You will be taken to re-enter the payment details and then you may complete this application session.");
          }
          this.isSubmissionModalShowing = false;
          this.goToPaymentInfo();
        }
      } catch (e) {
        console.log("The error in submit handler is", e);
        this.handleFetchError();
      }
},


    redirectToEnrollmentCompletePage() {
      if (this.isRemoteSignature) {
        this.$router.push({name: 'RemoteWizardComplete'});
      } else {
        this.$router.push({name: 'WizardComplete'});
      }
    },

    serializeEnrollmentData() {
      return {
        initial_data: this.initialData,
        current_step_index: this.currentStepIndex,
        data: {
          applicants: this.applicants.serialize(),
          selectedCoverages: this.selectedCoverages.serialize(),
          paymentInformation: this.paymentInformation,
        },
      }
    },
    handleFetchError(msg = "There was an error communicating with the server.") {
      return () => {
        this.stepValidationError = msg;
        // Prevent further promise then handlers from running.
        return false;
      }
    },

    updateSubmitButtonText() {
      if (this.isRemoteSignature) {
        this.submitButtonText = "Sign Application";
        return;
      }

      if (!this.isSelfEnroll && !this.isAgentEnrollRemoteSignature) {
        if (this.empSelectedRemoteSignature) {
          this.submitButtonText = "Finish and Send for Signature"
          return;
        }
      }

      this.submitButtonText = "Finish and Submit Application";
    },

    getResumeStepIndexIfAllowed() {
      // Are we resuming, did we save the step index, and is it less than the current number of steps?
      if ((!this.initialData.resumed_enrollment_data ||
        !this.initialData.resumed_enrollment_data.current_step_index ||
        !(this.initialData.resumed_enrollment_data.current_step_index < this.steps.length))) {
        return 0;
      }

      // If the case has been modified since we paused, do not attempt to resume
      const pausedTime = moment(this.initialData.paused_time);
      if (this.initialData.case.updated_at &&
        moment(this.initialData.case.updated_at).isAfter(pausedTime)) {
        return 0;
      }

      if (this.initialData.start_audio_conference) {
        // When we are using an audio conference, ALWAYS restart at the beginning.
        return 0;
      } else if (this.initialData.previous_start_audio_conference === true) {
        // If we were using an audio conference, but are not now, we need to restart at the beginning.
        return 0;
      }

      // @TODO not sure if this is necessary, but leaving here for future generations - EF 2023-01-05
      // if (this.initialData.previous_enrollment_mode && this.initialData.previous_enrollment_mode !== this.initialData.enrollment_mode) {
      //   // If the enrollment mode has changed, we need to restart at the beginning.
      //   return 0;
      // }

      return this.initialData.resumed_enrollment_data.current_step_index;
    },

    /**
     * Retrieves the conference status, and will continue to re-poll for new statuses
     * as long as `this.isConferenceStarting` == true
     * @return {Promise<void>}
     * @TODO duplicate code from new-enrollment-modal.vue. Merge this into something reusable
     */
    async updateConferenceEventsSubscription(tryUntilConferenceStart = false) {
      console.debug('Getting conference status. this.isConferenceStarting: ', this.isConferenceStarting);
      const pollDelay = EnrollmentConferenceSessions.POLL_DELAY;
      window.__EE_confStatusSubTimeout = null;

      // always get at least one status
      Api.getCurrentConferenceStatus()
        .then(responseJson => {
          if (responseJson && !responseJson.errors) {
            if (!this.isConferenceEnded) { // once the conference is ended, we don't want to update anymore
              console.debug('Updating subscription to conf status events:', responseJson);
              bus.$emit('conference-status-updated', responseJson);
            }

            this.$nextTick(() => {
              if (!this.isConferenceReadyToStart) {  // are we active baaed on the first request?
                window.__EE_confStatusSubTimeout = setTimeout(() => {
                  if (!this.isConferenceReadyToStart) {  // are we still active after waiting pollDelay ms?
                    // if so, request and re-evaluate
                    this.updateConferenceEventsSubscription();
                  }
                }, pollDelay);
              }
            });
          } else if (tryUntilConferenceStart) {
            console.debug('tryUntilConferenceStart is true. this.isConferenceStarting: ', this.isConferenceStarting);
            this.updateConferenceEventsSubscription(tryUntilConferenceStart);
          }
        });
    },

    // @TODO duplicate code from new-enrollment-modal.vue. Merge this into something reusable
    stopConferenceEventsSubscription() {
      // this will stop the timeout that keeps grabbing
      clearTimeout(window.__EE_confStatusSubTimeout);
    },

    // @TODO duplicate code from new-enrollment-modal.vue. Merge this into something reusable
    cancelConferenceAndResetState() {
      this.stopConferenceEventsSubscription();

      if (this.isAudioConferenceEnrollment && !this.isConferenceReadyToStart) {
        this.isConferenceCancelling = true;
        this.isConferenceCancelled = false;

        bus.$emit('cancel-enrollment-session-conference');

        this._cancelConference()
          .then((r) => {
            this.isConferenceCancelling = false;
            this.isConferenceCancelled = true;
          })
          .catch((reason) => {
            this.isConferenceCancelling = false;
            this.isConferenceCancelled = false;
          });
      }

      this.conferenceStatus = null;
      this.conferenceStatusReason = null;
      this.conferenceEnd = null;
    },

    // @TODO duplicate code from new-enrollment-modal.vue. Merge this into something reusable
    _cancelConference() {
      return Api.cancelCurrentConference()
        .then((r) => {
          console.debug("Maybe do something when current conference is cancelled.", r);
        })
        .catch((reason) => {
          console.warn("Problem cancelling current conference.", reason);
        })
        .finally(() => {
        });
    },

    handleConferenceEndedUnexpectedly() {
      // immediately pause the enrollment via the API
      this._pauseEnrollment()
        .then((r) => {
          console.log('Session should be auto-paused and show user overlay');
        });

    },

    exitToEnrollmentDetails() {
      this.cancelConferenceAndResetState();
      this.redirectToCurrentEnrollmentSessionDetails();
    },
    redirectToCurrentEnrollmentSessionDetails() {
      this.$router.push({name: 'WizardConfFailedAutoPaused'});
    },


  },
  watch: {
    // whenever question changes, this function will run
    empSelectedRemoteSignature: function (newVal, oldVal) {
      this.updateSubmitButtonText();
    },
    async isConferenceEnrollment() {
      // when this changes, we should re-evaluate if we need to subscribe to updates
      await this.updateConferenceEventsSubscription();
    },
    async isConferenceEndedUnexpectedly(newVal, oldVal) {
      // when this changes to true, we need to
      if (newVal !== oldVal) {
        if (newVal) {
          await this.handleConferenceEndedUnexpectedly()
        }
      }
    }

  }
};

export default wizard
</script>


<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .3s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}


/* Ordering is important for these two sections. */
/* What to animate to when sliding down, and what to start from when class is removed. */
.slideDown-enter-active, .slideDown-leave-active {
    max-height: 500px;
    overflow-y: hidden;
    overflow-x: hidden;

    transition: max-height .3s ease-in-out;
}

/* Starting (when transition started) and ending states (when slideDown removed has finished) */
.slideDown-enter, .slideDown-leave-to {
    max-height: 0;
}

.totalPremiumSection {
    font-size: 70%;
    margin-right: 2rem;
}

.totalPremiumAmount {
    font-weight: bold;
}
</style>
