<template>
  <div>
    <show-navigation></show-navigation>

      <div v-if="!isLoading" class="row p-3 mb-3 align-items-center">
        <span v-if="cases.length > 0" class="pr-3">{{ $root.terminology.formatOpenCase() }}</span>
        <div v-if="cases.length > 0" class="pr-3">
          <b-form-select
            :value="caseFilterValue"
            @change="caseFilterChange"
            :options="casesFilterOptions"
            >
          </b-form-select>
        </div>
        <div v-if="userCanCreateCase" class="pr-3">
          <b-button
            type="button"
            variant="success"
            @click="openNewCaseModal">
            <font-awesome-icon icon="plus"></font-awesome-icon> New {{ "case" | terminology({isTitle: true})}}
          </b-button>
        </div>
      </div>

      <loading-spinner v-show="isLoading" useWholePage></loading-spinner>

      <div v-show="!isLoading && cases.length===0" class="row justify-content-center py-4">
        {{ userPermissions.getFirstName() }}, you have not been granted access to any {{$root.terminology.formatEnrollment({})}} {{ $root.terminology.formatCase({isPlural: true})}} yet. Please contact your administrator.
      </div>

      <b-tabs id="case-tabs" class="col-auto px-0" v-model="activeTabIndex" v-show="!isLoading && cases.length > 0">

        <b-tab v-for="(tab, index) in tabs" :key="tab.case.id" :title-link-class="tab.class">

          <template slot="title">
            <div>
              <span>{{ tab.case.group_name }}</span>
              <span>
                <b-badge v-if="tab.component === 'agent-inbox' && shouldShowAgentInboxBadge"
                  pill variant="warning">{{ agentInboxTotalCount }}</b-badge>
              </span>
              <font-awesome-icon
                class="ml-2"
                icon="times"
                v-if="tab.case.id && tab.case.id !== INBOX_CASE_ID"
                @click.prevent.stop="closeCaseFilter(index)"
              ></font-awesome-icon>

            </div>
          </template>

          <div v-if="tab.component === 'agent-inbox'">
            <agent-inbox @update:showLoading="isLoading = $event" @update:totalCount="setAgentInboxTotalCount($event)">

            </agent-inbox>
          </div>

          <div v-else><!-- enrollments "component" -->

            <div class="tab-actions row col-12 p-3 mx-0 justify-content-between">

                <div class="row col-12 col-md-6 p-0 mx-0 mb-xs-0 mb-md-3 mb-lg-0 justify-content-between">

                  <h3 class="col-xs-0 col-sm-0 col-lg-12 pl-0 pr-3">
                    {{tab.case.group_name}} {{(!tab.case.id ? $root.terminology.formatEnrollment({isPlural: true, isTitle: true}) : '')}}
                  </h3>

                  <div class="d-none d-md-flex">
                    <div v-if="userCanCreateEnrollment" class="custom-button mr-3">
                      <b-button
                        type="button"
                        variant="success"
                        @click="openNewEnroll(tab)">
                        <i class="fa fa-plus fa-2x"></i>
                      </b-button>
                      <label>New {{ "enrollment" | terminology({isShort: true}) }}</label>
                    </div>

                    <div v-if="tab.case.id && userCanChangeCase" class="custom-button mr-3">
                      <b-button
                        :disabled="tab.showCaseSection"
                        type="button"
                        variant="outline-primary"
                        @click="showCaseSetup(tab)">
                        <i class="fa fa-cog fa-2x"></i>
                      </b-button>
                      <label>Setup</label>
                    </div>

                    <div v-if="tab.case.id > 0" class="custom-button mr-3">
                      <b-button
                        type="button"
                        variant="outline-primary"
                        @click="viewStats">
                        <font-awesome-icon icon="chart-pie" size="2x"></font-awesome-icon>
                      </b-button>
                      <label>Stats</label>
                    </div>

                    <div class="custom-button mr-3" v-if="siteConfig.enableEnrollmentsDownload" v-show="!isGeneratingCaseDownload">
                      <b-button
                        type="button"
                        :class="{ 'btn-outline-white': !tab.case.id }"
                        variant="outline-primary"
                        @click="startGeneratingCaseDownload()"
                        :disabled="isGeneratingCaseDownload"
                      >
                        <i class="fa fa-download fa-2x"></i>
                      </b-button>
                      <label>Download</label>
                    </div>

                    <div class="custom-button mr-3" v-show="isGeneratingCaseDownload">
                      <b-button
                        type="button"
                        variant="outline-secondary"
                      >
                        <i class="fa fa-spinner fa-spin fa-2x"></i>
                      </b-button>
                      <label>Download</label>
                    </div>

                    <div v-if="tab.case.id > 0 && siteConfig.enableCaseCensus && userCanManageCensus" class="custom-button mr-3">
                      <b-button
                        type="button"
                        variant="outline-primary"
                        @click="manageCensus">
                        <i class="fa fa-address-book fa-2x"></i>
                      </b-button>
                      <label>Census</label>
                    </div>

                  </div>

                  <b-dropdown id="ddown1" :text="$root.terminology.formatCase({isTitle: true}) + ' Actions'" class="m-md-2 d-md-none">
                    <b-dropdown-item
                      v-if="userCanCreateEnrollment"
                      @click="openNewEnroll(tab)">
                      <i class="fa fa-plus fa-2x"></i> New {{ "enrollment" | terminology({isShort: true}) }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="tab.case.id && userCanChangeCase"
                      @click="tab.showCaseSection = true"
                      :disabled="tab.showCaseSection">
                      <i class="fa fa-cog fa-2x"></i> Setup
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="viewStats">
                      <i class="fa fa-chart-pie fa-2x"></i> Stats
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="startGeneratingCaseDownload(tab)">
                      <i class="fa fa-download fa-2x"></i> Download
                    </b-dropdown-item>
                  </b-dropdown>
                </div>

                <div class="row col-md-6 px-3 pt-3 p-md-0 pl-xs-3 pl-sm-3 pl-md-3 pl-lg-0">
                  <div class="col-0">
                    <b-form-select
                      v-model="tab.filters.enrollmentFilterValue"
                      :options="enrollmentFilterOptions"
                      v-on:change="searchCaseEnrollments()">
                    </b-form-select>
                  </div>

                  <div class="col pr-0 pr-sm-3">
                    <b-input-group @keydown.enter="searchCaseEnrollments()">
                      <b-form-input
                        class="col"
                        v-model="tab.filters.caseSearch"
                        type="text"
                        placeholder="Search">
                      </b-form-input>
                      <b-input-group-append>
                        <b-btn variant="primary" @click="searchCaseEnrollments()"><i class="fa fa-search"></i></b-btn>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>


                <b-collapse
                  id="collapseCaseSection"
                  v-model="tab.showCaseSection"
                  class="mt-3 w-100">
                   <!-- card-body needed here for smooth opening animation -->
                  <div class="card-body">
                    <setupcase
                        v-if="tab.showCaseSection"
                        :Case="tab.case"
                        :products="products"
                        :tags="tags"
                        :users="users"
                        :userPermissions="userPermissions"

                        v-on:delete-case-listener="removeCase"
                        v-on:update-case-listener="updateCase"
                        v-on:switch-tab-listener="switchTab"
                        v-on:close-section-listener="tab.showCaseSection=false">
                      </setupcase>
                    </div>
                </b-collapse>

                <b-collapse
                  id="collapseCensusSection"
                  v-model="tab.isCensusShowing"
                  class="mt-3 w-100">
                   <!-- card-body needed here for smooth opening animation -->
                  <div class="card-body">
                    <managecensus
                        v-if="tab.isCensusShowing"
                        :Case="tab.case"
                        :products="products"
                        :userPermissions="userPermissions"

                        @delete-case-listener="removeCase"
                        @update-case-listener="updateCase"
                        @close-manage-census="tab.isCensusShowing=false">
                      </managecensus>
                    </div>
                </b-collapse>

                <b-collapse
                  id="collapseStatsSection"
                  v-model="tab.isStatsShowing"
                  class="mt-3 w-100">
                   <!-- card-body needed here for smooth opening animation -->
                  <div class="card-body">
                    <case-detail-stats-wrap
                        v-if="tab.isStatsShowing"
                        :case-id="tab.case.id"
                        @close="tab.isStatsShowing=false"
                      >
                      </case-detail-stats-wrap>
                    </div>
                </b-collapse>

              </div>

            <b-table
              striped
              hover
              :items="tab.enrollments"
              :fields="fields"
              class="mb-0 pointer"
              @row-clicked="enrollmentClick"
              @sort-changed="sortChanged"
              :sort-by.sync="tab.sortBy"
              :sort-desc.sync="tab.sortDesc"
              no-local-sorting>

              <sorting
                v-for="field in fields"
                :slot="'HEAD_'+field.key"
                slot-scope="data"
                v-if="field.label"
                :field="field"
                :sortBy="tab.sortBy"
                :sortDesc="tab.sortDesc">
              </sorting>

              <template v-slot:cell(actions)="row">
                <b-button size="sm" v-if="row.item.status !== 'census' && row.item.status !== 'waived'" @click.stop="viewPDF(row.item.id)"
                  variant="primary"
                  v-b-tooltip.hover title="View PDF">
                  <i class="far fa-file-pdf"></i>
                </b-button>
                <b-badge v-if="row.item.has_enrollment_session_conference" squared size="sm" variant="link" v-b-tooltip.hover title="Uses Voice Enrollment Feature">
                  <font-awesome-icon icon="phone"></font-awesome-icon>
                </b-badge>

              </template>
            </b-table>

            <pdf-modal :enrollment-id="viewingEnrollmentId" :visible="showPDFModal"
                        @hidden="showPDFModal = false">
            </pdf-modal>

            <loading-spinner v-show="tableIsLoading" class="p-3"></loading-spinner>

            <div v-if="tab.enrollments.length === 0 && !tableIsLoading" class="p-4 text-center">
              No  {{ 'enrollment' | terminology({isPlural: true}) }} match your filters.
            </div>



          </div>
        </b-tab>

        <div class="row mx-3 pt-3" v-if="activeTab && activeTab.component === 'enrollments'">
          <b-pagination
            size="sm"
            :total-rows="activeTab ? activeTab.totalCount : 0"
            v-model="currentPage"
            :per-page="rowsPerPage"
           >
          </b-pagination>

          <div class="pl-3">
            <b-form-select
              class="col-0 pag-row-number"
              v-model="rowsPerPage"
              :options="rowsPerPageOptions">
            </b-form-select>
          </div>
        </div>

      </b-tabs>

      <new-enrollment-modal
        v-if="showEnrollModal"
        :newEnrollment="newEnrollment"
        :casesFilterOptions="casesFilterOptions"
        v-on:close-new-enrollment-modal="showEnrollModal=false">
      </new-enrollment-modal>

      <!-- New Case Modal -->
      <b-modal
        v-if="userCanCreateCase"
        :title="'Create '+$root.terminology.formatCase({isTitle: true})"
        v-model="showNewCaseModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true">

        <b-container>
          <div class="column">
            <div class="form-group col-6 p-0">
              <label>Name:</label>
              <b-form-input
                class="col"
                v-model="caseForm.group_name"
                type="text"
                placeholder="">
              </b-form-input>
            </div>
          </div>

          <div v-if="caseErrors.length>0" class="mt-4">
            <b>Please correct the following error<span v-if="caseErrors.length>1">s</span>:</b>
            <ul>
              <li class="text-danger" v-for="error in caseErrors">{{ error.message }}</li>
            </ul>
          </div>
        </b-container>

        <div slot="modal-footer" class="row align-self-end">
          <b-btn
            @click="showNewCaseModal = false"
            size="md"
            class="col-0 mr-3"
            variant="outline-secondary">
            <i class="fa fa-times"></i> Close
          </b-btn>
          <b-btn
            @click="createCase()"
            size="md"
            class="col-0 mr-3"
            variant="primary">
            Save
          </b-btn>
        </div>

      </b-modal>


      <b-modal
        title="Saving"
        v-model="showSavingModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true">

        <b-container>
          <div>
            <loading-spinner size="large"></loading-spinner>
          </div>
        </b-container>

        <div slot="modal-footer" class="row align-self-end">

        </div>

      </b-modal>

      <b-modal
        title="You have unsaved changes"
        v-model="showUnsavedTabChangesModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true">

        <b-container>
          Are you sure you want to leave this tab without saving your changes?
        </b-container>

        <div slot="modal-footer" class="row align-self-end">
          <b-btn
            size="md"
            class="mr-3"
            variant="outline-secondary"
            @click="showUnsavedTabChangesModal=false">
            <i class="fa fa-times"></i> No, return to tab
          </b-btn>
          <b-btn
            size="md"
            class="mr-3"
            variant="danger"
            @click="switchTab">
            Yes, do not save changes
          </b-btn>
        </div>

      </b-modal>


  </div>

</template>

<script>

import setupcase from './case_setup/setup-case.vue'
import caseDetailStatsWrap from './cases/case-detail-stats-wrap.vue'
import managecensus from './census/manage-census.js'
import newEnrollmentModal from './new-enrollment-modal.vue'
import agentInbox from './agent-inbox.js'
import Api from './api.js'
import states from './models/states.js'
import getEnrollmentTableFields from './models/enrollments-table-fields.js'
import sorting from './models/sorting.js'
import formats from './models/formats.js'
import rowsPerPageOptions from './models/rows-per-page-options.js'
import bus from "./bus.js"
import Vue from 'vue'
import ShowNavigation from "./nav/ShowNavigation";
import {siteConfig, terminology} from "./app";
import PdfModal from "./PdfModal";
import modalListenerMixin from './mixins/modalListenerMixin.js'

const INBOX_CASE_ID = -99999;

export default {
    name: "Enrollments",
    components: {
      setupcase,
      sorting,
      newEnrollmentModal,
      agentInbox,
      managecensus,
      caseDetailStatsWrap,
      ShowNavigation,
      PdfModal,
    },
    mixins: [modalListenerMixin],
    props: {
      tab: Object,
      userPermissions: { type: Object },
    },
    data () {
      return {
        fields: getEnrollmentTableFields(),
        enrollments: [],
        cases: [],
        caseErrors: [],
        enrollmentFilterOptions: [],
        rowsPerPageOptions: rowsPerPageOptions,
        caseSearch: '',
        currentPage: 1,
        rowsPerPage: 25,
        showEnrollModal: false,
        showNewCaseModal: false,
        enrollmentFilterValue: '',
        casesFilterOptions: [],
        caseFilterValue: null,
        statesOptions: states,
        newEnrollment: {
          caseId: null,
          city: null,
          state: null,
          ssn: null,
        },
        tabs: [],
        allEnrollments: [],
        caseForm: {group_name: ''},
        areYouSureCaseId: '',
        defaultSortColumn: 'time',
        tags: [],
        users: [],
        products: [],
        activeTabIndex: 0,
        isLoading: true,
        tableIsLoading: true,
        showUnsavedTabChangesModal: false,
        showSavingModal: false,
        agentInboxTotalCount: 0,
        INBOX_CASE_ID: INBOX_CASE_ID,
        isGeneratingCaseDownload: false,
        siteConfig: siteConfig,

        showPDFModal: false,
        viewingEnrollmentId: null,
      }
    },
    methods: {
      searchEnrollments(params) {
        Object.assign(params, {
          rows_per_page: this.rowsPerPage,
          page: this.currentPage
        });
        return Api.searchEnrollments(params)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
          } else {
            return response;
          }
        });
      },
      createCase() {
        Api.createCase(this.caseForm)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            this.caseErrors = response.errors;
          } else {
            this.caseErrors = [];
            Object.assign(this.caseForm, response);
            this.cases.push(response);
            this.casesFilterOptions = this.mapCases();
            this.caseFilterValue = response.id;

            if (!this.userPermissions.isHomeOfficeAdmin()) {
              this.addUserToCase(response.id).then(() => {
                this.caseFilterChange(response.id, true);
                this.showNewCaseModal = false;
              });
            } else {
              this.caseFilterChange(response.id, true);
              this.showNewCaseModal = false;
            }
          }
        });
      },
      addUserToCase(caseId) {
        let users = [{
          id: this.userPermissions.getUserId(),
          restricted_to_own_enrollments: false
        }];
        return Api.addUsersToCase(caseId, {users:users});
      },
      updateCase(tab) {
        this.casesFilterOptions = this.mapCases();
      },
      removeCase(caseId) {
        this.cases = this.cases.filter(function(c) {return c.id !== caseId});
        this.casesFilterOptions = this.mapCases();
        this.tabs = this.tabs.filter(function(t) {return t.case.id !== caseId});
        this.caseFilterValue = null;
        this.activeTabIndex = 0;
      },
      startGeneratingCaseDownload() {
        let tab = this.activeTab;
        let params = {
          case_id: tab.case.id ? tab.case.id : '',
          search_type: tab.filters.enrollmentFilterValue,
          search_text: tab.filters.caseSearch,
          search_format: 'csv'
        };

        this.isGeneratingCaseDownload = true;

        Api.startGeneratingEnrollmentCsv(params)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            console.error(response.errors);
            alert("Unexpected error starting export.");
          } else {
            let msg = 'Download generating. You will receive an email shortly.'
            if (response && response.msg) {
              msg = response.msg
            }
            alert(msg);
            this.isGeneratingCaseDownload = false;  // @todo this might not be necessary if done in .finally()
          }
        }).finally(() => {
          this.isGeneratingCaseDownload = false;
        });
      },
      showCaseSetup(tab) {
        tab.showCaseSection = true;

        tab.isCensusShowing = false;
        tab.isStatsShowing = false;
      },
      manageCensus() {
        const activeTab = this.tabs[this.activeTabIndex];
        if (activeTab.isCensusShowing) {
          activeTab.isCensusShowing = false;
        } else {
          activeTab.isCensusShowing = true;
          activeTab.showCaseSection = false;
          activeTab.isStatsShowing = false;
        }
      },
      viewStats() {
        const activeTab = this.tabs[this.activeTabIndex];
        if (activeTab.isStatsShowing) {
          activeTab.isStatsShowing = false;
        } else {
          activeTab.isStatsShowing = true;

          activeTab.showCaseSection = false;
          activeTab.isCensusShowing = false;
        }
      },
      searchCases() {
        return Api.searchCases()
          .then(response => {
            this.cases = response;
            this.casesFilterOptions = this.mapCases();
            this.caseFilterValue = null;
          });
      },
      mapCases() {
        let options = this.cases.map(c => {
          return {text: c.group_name, value: c.id};
        });
        options = formats.alphebetize(options, 'text');
        options.unshift({text: `Select ${this.$root.terminology.formatCase({isTitle: true})}`, value: null});
        return options;
      },
      searchTypes() {
        return Api.searchTypes()
        .then(response => {
          this.enrollmentFilterOptions = this.mapTypes(response);
        });
      },
      mapTypes(types) {
        let options = types.map(v => {
          let text;
          if (v === 'applicant') {
            text = 'Insured';
          } else if (v === 'case') {
            text = terminology.formatCase({isTitle: true})
          } else {
            // Capitalize
            text = `${v[0].toUpperCase()}${v.slice(1)}`;
          }
          return {
            text: text,
            value: v
          };
        });
        this.enrollmentFilterValue = options[0].value;
        return options;
      },
      removeUnwantedTabs() {
        this.tabs = this.tabs.filter(tab => {
          // Check if tab has no tab.case.id or if tab.case.id is equal to INBOX_CASE_ID
          return !tab.case.id || tab.case.id === INBOX_CASE_ID;
        });
      },
      caseFilterChange(caseId, showSettings) {
        let openCaseTab = this.tabs.find(c => {return c.case.id === caseId});

        if(openCaseTab === undefined) {
          let selectedCase = this.cases.find(c => c.id === caseId);
          let newTab = this.initNewTab(selectedCase);
          openCaseTab = newTab;
          this.removeUnwantedTabs();
          this.tabs.push(newTab);
        }

        // Wait until the current Vue events have flushed and UI is updated, then switch to the new tab.
        window.setTimeout(() => {

          this.setActiveTab(openCaseTab);

          // Reset the select dropdown to "Select Case"
          this.caseFilterValue = null;

          if (showSettings) {
            openCaseTab.showCaseSection = true;
          }

        }, 0);
      },
      searchCaseEnrollments() {
        this.tableIsLoading = true;
        let tab = this.activeTab;
        if (!tab || tab.case.id === INBOX_CASE_ID) {
          return;
        }
        tab.enrollments = [];
        let params = {
          case_id: tab.case.id ? tab.case.id : '',
          search_type: tab.filters.enrollmentFilterValue,
          search_text: tab.filters.caseSearch,
          //page: tab.page,
          sort_column: tab.sortBy,
          sort_direction: tab.sortDesc ? 'DESC' : 'ASC'
        };

        return this.searchEnrollments(params)
        .then(response => {
          tab.totalCount = response.total_count;
          tab.enrollments = response.rows;

          if(tab.case.group_name === 'Pending') {
            tab.enrollments = tab.enrollments.filter(e => {return e.status === 'pending'});
          }

          this.tableIsLoading = false;
        });
      },
      refreshAgentInbox() {
        if (!siteConfig.ffEnableAgentInbox) {
          return;
        }

        let tab = this.activeTab;
        if (!tab) {
          return;
        }
        if (tab.component === 'agent-inbox') {
          bus.$emit('refreshAgentInbox');
        }
      },
      initializeTabs() {

        let paramId;

        // special handling if case_id is 'inbox'
        if (siteConfig.ffEnableAgentInbox && this.$route.params.case_id === 'inbox') {
          paramId = INBOX_CASE_ID;
        }

        this.tabs = [this.initNewTab({group_name: 'All'})];

        if (siteConfig.ffEnableAgentInbox) {
          this.tabs.push(this.initAgentInboxTab());
        }

        if (siteConfig.ffEnableAgentInbox && paramId === INBOX_CASE_ID) {
          this.tabs[1].isActive = true;
          window.setTimeout(() => {
            this.setActiveTab(this.tabs[1]);
          }, 0);
        }
        Vue.nextTick(()=> {
          this.searchCaseEnrollments();
        });
      },
      initNewTab(fcase) {
        let defaultFilters = {
          enrollmentFilterValue: this.enrollmentFilterOptions[0].value,
          caseSearch: '',
        };

        return {
          component: "enrollments",
          class: "",
          case: fcase,
          filters: defaultFilters,
          enrollments: [],
          sortBy: this.defaultSortColumn,
          sortDesc: true,
          showCaseSection: false,
          isCensusShowing: false,
          isStatsShowing: false,
          totalCount: null,
        };
      },
      initAgentInboxTab() {
        return {
          component: "agent-inbox",
          class: "agent-inbox",
          case: {group_name: 'Inbox', id: INBOX_CASE_ID},  // used for label in tab
          filters: {
            enrollmentFilterValue: this.enrollmentFilterOptions[0].value,  // not used
            caseSearch: '',  // not used
          },
          enrollments: [],  // not used
          enrollmentSessions: [],
          sortBy: this.defaultSortColumn,
          sortDesc: true,
          showCaseSection: false,   // not used
          isCensusShowing: false,
          isStatsShowing: false,
          totalCount: null,
        }
      },
      openNewCaseModal() {
        this.caseErrors = [];
        this.caseForm = {
          group_name: '',
          enrollment_open: true,
          premium_mode: 'monthly'
        };
        this.showNewCaseModal = true;
      },
      sortChanged(header) {
        if(header.sortBy) {
          this.activeTab.sortBy = header.sortBy;
          this.activeTab.sortDesc = header.sortDesc;
          this.searchCaseEnrollments();
        }
      },
      getAllProducts() {
        return Api.searchProducts()
        .then(response => {
          // sort by name
          response = response.sort(
            function compare(p1, p2) {
              if (p1['name'] < p2['name']) {
                return -1;
              }
              if (p1['name'] > p2['name']) {
                return 1;
              }
              return 0;
            });
          return this.products = this.mapMultiSelect(response);
        });
      },
      getAllTags() {
        return Api.searchTags()
        .then(response => {
          this.tags = this.mapMultiSelect(response);
        });
      },
      mapMultiSelect(response) {
        return response.map(r => {return {value:r.id, text:r.name}});
      },
      searchUsers() {
        Api.searchUsers()
        .then(response => {
          this.users = response.rows.map(r => {
            return {
              text: (r.first_name+' '+r.last_name),
              value: r.id,
              restrict: false,
              isApiUser: r.is_api_user,
            }
          });
        });
      },
      openNewEnroll(tab) {
        if(localStorage.enrollmentState && localStorage.enrollmentCity) {
          this.newEnrollment = setNewEnrollment((tab.case.id ? tab.case.id : null), localStorage.enrollmentCity, localStorage.enrollmentState);
        } else {
          if (tab) {
            this.newEnrollment = setNewEnrollment(tab.case.id, tab.case.situs_city, tab.case.situs_state);
          } else {
            this.newEnrollment = setNewEnrollment();
          }
        }

        this.showEnrollModal = !this.showEnrollModal;
        function setNewEnrollment(caseId=null, city, state) {
          return {
            caseId: caseId,
            city: city,
            state: state,
            ssn: null
          }
        }
      },
      closeCaseFilter(index) {
        // Right now, this allows modified case tabs to be closed without saving changes.

        //if (index === this.activeTabIndex && this.activeTab.showCaseSection && this.checkUserChanges()) {
        //  this.showUnsavedTabChangesModal
        //} else {
          this.tabs.splice(index, 1);
        //}
      },
      setActiveTab(tab) {
        this.activeTabIndex = this.tabs.indexOf(tab);
      },
      tabClick(index) {
        this.switchTab(this.activeTab.case.id);
      },
      switchTab(caseId) {

      },
      viewPDF(enrollmentId) {
        this.viewingEnrollmentId = enrollmentId;
        this.showPDFModal = true;
      },
      enrollmentClick(record) {
        // Census will have an applicant id on the record.
        if (record.status === 'census') {
          this.$router.push('/census-details/'+record.id);
        } else {
          this.$router.push({name: 'enrollmentDetails', params: {id: record.id}});
        }
      },
      loadSavedTabs() {
        let savedTabs = JSON.parse(localStorage.savedTabs);

        let handleCaseIdParam = () => {
          let paramId;
          let caseIdExistsInUserCases;

          // special handling if case_id is 'inbox' -- if it is enabled - EF 2023-01-03
          if (siteConfig.ffEnableAgentInbox && this.$route.params.case_id === 'inbox') {
            paramId = INBOX_CASE_ID;
            caseIdExistsInUserCases = true;
          } else {
            paramId = parseInt(this.$route.params.case_id);
            caseIdExistsInUserCases = this.cases.filter(c => { return c.id === paramId }).length > 0;
          }

          let caseIsAlreadyInSavedTabs = savedTabs.findIndex(t => t.caseId === paramId) > -1;

          if(caseIdExistsInUserCases) {
            savedTabs.forEach(t => {
              t.isActive = false;
            });
            if(caseIsAlreadyInSavedTabs) {
              let activeTabWithOpenCaseSetup = savedTabs.filter(t => { return t.caseId === paramId })[0];
              activeTabWithOpenCaseSetup.isActive = true;
              activeTabWithOpenCaseSetup.showCaseSection = false;
            } else {
              savedTabs.push({
                caseId: paramId,
                showCaseSection: false,
                isActive: true
              });
            }
          }
          return paramId;
        };

        let initAllTab = () => {
          let savedAllTab = savedTabs.filter(t => !t.caseId)[0];
          let newAllTab = this.initNewTab({group_name: 'All'});
          this.tabs.push(newAllTab);
          if(savedAllTab.isActive) {
            Vue.nextTick(()=> {
              this.searchCaseEnrollments();
            });
          }
          savedTabs = savedTabs.filter(t => t.caseId);
        };

        let initAgentTab = () => {
          if (!siteConfig.ffEnableAgentInbox) {
            return;
          }

          let agentInboxTab = this.initAgentInboxTab();
          if (passedParamId === INBOX_CASE_ID) {
            agentInboxTab.isActive = true;
          }
          this.tabs.push(agentInboxTab);
          if(agentInboxTab.isActive) {
            window.setTimeout(() => {
              this.setActiveTab(agentInboxTab);
            }, 0);
          }
        };

        const passedParamId = handleCaseIdParam();
        initAllTab();
        initAgentTab();

        this.isLoading = false;

        // init case tabs
        window.setTimeout(() => {
          savedTabs.forEach(savedTab => {
            let thisCaseObject = this.cases.filter(c => {
              return c.id === savedTab.caseId
            })[0];
            if (thisCaseObject) {
              let newTab = this.initNewTab(thisCaseObject);
              this.tabs.push(newTab);

              const diffActiveTabExists = savedTabs.filter((st) => {
                return st.isActive === true && st.caseId !== savedTab.caseId
              }).length > 0;

              if (!diffActiveTabExists && savedTab.isActive) {
                window.setTimeout(() => {
                  this.setActiveTab(newTab);
                }, 0);
              }
            } else if (siteConfig.ffEnableAgentInbox) {
              const inboxIsActive = savedTabs.findIndex(t => t.caseId === INBOX_CASE_ID && t.isActive) > -1;
              if (inboxIsActive) {
                const inboxTab = this.tabs.find(t => {
                  return t.case.id === INBOX_CASE_ID;
                });
                if (inboxTab) {
                  window.setTimeout(() => {
                    this.setActiveTab(inboxTab);
                  }, 0);
                }
              }
            }
          });
        }, 0);


      },
      saveTabs() {
        let savedTabs = this.tabs.map((t, index) => {
          return {
            caseId: t.case.id,
            isActive: index === this.activeTabIndex,
            filters: t.filters
          }
        });

        localStorage.savedTabs = JSON.stringify(savedTabs);
      },
      setAgentInboxTotalCount(value) {
        this.agentInboxTotalCount = value;

        // this seems to be required to force the tab to re-render its title slot and show the correct value
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    },
    created() {
      let rowsPerPage = parseInt(localStorage.rowsPerPage);
      this.rowsPerPage = rowsPerPage && this.rowsPerPageOptions.includes(rowsPerPage) ? rowsPerPage : this.rowsPerPage;


      this.searchTypes()
      .then(() => {
        return this.searchCases();
      })
      .then(() => {
        if(this.cases.length > 0) {
          if (localStorage.savedTabs && JSON.parse(localStorage.savedTabs).length > 0) {
            this.loadSavedTabs();
          } else {
            this.initializeTabs();
            this.isLoading = false;
          }
        } else {
          this.isLoading = false;
        }
      });

      this.getAllTags();
      if (this.userPermissions.canManageUsersAndProducts()) {
        this.searchUsers();
        this.getAllProducts();
      }

      window.addEventListener('beforeunload', this.saveTabs);

      bus.$on('new-census-uploaded', () => {
        this.searchCaseEnrollments();
      });

      bus.$on('case-saving-started', () => {
        this.showSavingModal = true;
      });

      bus.$on('case-saving-ended', () => {
        this.showSavingModal = false;
      });


    },
    computed: {
      userCanCreateEnrollment() {
        return this.userPermissions.canEnroll();
      },
      userCanCreateCase() {
        return this.userPermissions.canCreateCase();
      },
      userCanChangeCase() {
        return this.userPermissions.canChangeCase();
      },
      userCanManageCensus() {
        return this.userPermissions.canManageCensus();
      },
      activeTab() {
        if (this.activeTabIndex < this.tabs.length) {
          return this.tabs[this.activeTabIndex];
        } else {
          return this.tabs[0];
        }
      },
      shouldShowAgentInboxBadge() {
        return this.agentInboxTotalCount > 0;
      }
    },
    watch: {
      rowsPerPage: function() {
        if (!this.isLoading) {
          localStorage.rowsPerPage = this.rowsPerPage;
          this.searchCaseEnrollments();
        }
      },
      currentPage: function() {
        if (!this.isLoading) {
          this.searchCaseEnrollments();
        }
      },
      activeTabIndex: function(newIndex, oldIndex) {
        if (!this.isLoading) {
          this.searchCaseEnrollments();
          this.refreshAgentInbox();
        }
      },
      caseFilterValue: function(newId, oldId) {
      }
    },
    beforeRouteLeave(to, from, next) {
      this.saveTabs();

      next();
    }
}
</script>

<style scoped>

</style>
