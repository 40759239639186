<template>
  <b-overlay
    :show="showOverlay"
    no-fade
    opacity="0.9"
    variant="light">
    <div>

      <b-card v-if="paymentWithAppMade">
        <p>A first payment has already been made with this information. No changes are can be made.</p>
      </b-card>
      <b-card v-else-if="paymentInformationCaptured">
        <p>Payment Information has already been collected.</p>
        <b-button variant="warning" type="small" @click="clearPaymentInformation">Click to clear and re-enter Payment Information</b-button>
      </b-card>
      <b-card v-else no-body>
        <b-tabs card>
          <b-tab title="Pay via ACH transfer" :active.sync="bankTabIsActive"> <!-- the bank tab -->
            <b-form ref="bankForm" :validated="bankFormValidate">
              <b-form-group
                label="Account Holder Type"
              >
                <b-form-radio-group

                  class="pw-2"
                  v-model="bankHolderType"
                  :options="['personal', 'business']"
                  name="bankHolderType"
                  buttons
                  size="sm"
                  button-variant="outline-primary"
                >
                </b-form-radio-group>
              </b-form-group>

              <b-form-group
                label="Account Type"
              >
                <b-form-radio-group

                  class="pw-2"
                  v-model="bankType"
                  :options="['checking', 'savings']"
                  name="bankAccountType"
                  buttons
                  size="sm"
                  button-variant="outline-primary"
                >
                </b-form-radio-group>
              </b-form-group>

              <b-form-group
                label="Bank Routing Number"
                invalid-feedback="This field is required and must be 9 digits."
              >
                <b-form-input v-mask="'000000000'" v-model="bankRouting" placeholder="Routing Number" trim
                              :required="bankTabIsActive" :state="!!(bankRouting) && bankRouting.length === 9"
                              invalid-feedback="A valid 9-digit routing number is required."
></b-form-input>
              </b-form-group>

              <b-form-group
                label="Bank Account Number"
                invalid-feedback="This field is required."
              >
                <b-form-input v-mask-number v-model="bankAccount" placeholder="Account Number" trim
                              :required="bankTabIsActive" :state="!!bankAccount"></b-form-input>
              </b-form-group>
            </b-form>
          </b-tab>

          <b-tab title="Pay via credit card" :active.sync="cardTabIsActive">
            <b-form ref="cardForm" :validated="cardFormValidate">
              <b-form-group
                label="Card Number"
              >
                <div id="cardNumber"></div>
                <b-form-text v-if="cardNumberIsValid === true" text-variant="success">
                  <font-awesome-icon v-if="cardType === 'visa'" :icon="['fab', 'cc-visa']" />
                  <font-awesome-icon v-else-if="cardType === 'master'" :icon="['fab', 'cc-mastercard']" />
                  <font-awesome-icon v-else-if="cardType === 'american_express'" :icon="['fab', 'cc-amex']" />
                  <font-awesome-icon v-else-if="cardType === 'discover'" :icon="['fab', 'cc-discover']" />
                  <font-awesome-icon v-else icon="credit-card" />
                </b-form-text>
                <b-form-text v-if="cardNumberIsValid === false" text-variant="danger">
                  A valid credit card is required.
                </b-form-text>

<!--                <b-form-input v-mask-cc v-model="cardNumber" placeholder="Card Number" trim-->
<!--                              :required="cardTabIsActive"></b-form-input>-->
              </b-form-group>
              <b-form-group
                label="CVV"
              >
                <div id="cardCVV"></div>
                <b-form-text v-if="cardCvvIsValid" text-variant="success">
                  CVV <font-awesome-icon icon="check" />
                </b-form-text>
                <b-form-text v-else text-variant="danger">
                  A valid CVV is required.
                </b-form-text>

                <!--                <b-form-input v-mask="''"v-model="cardNumber" id="cardCVV" placeholder="Card Number" trim-->
<!--                              :required="cardTabIsActive"></b-form-input>-->
              </b-form-group>
              <b-form-group
                label="Card Expiration Month"
                :state="!!(cardExpMonth)"
                invalid-feedback="A valid month is required"
              >
                <b-form-select id="cardExpMonth" v-model="cardExpMonth" :options="[1,2,3,4,5,6,7,8,9,10,11,12]"
                               :required="cardTabIsActive" :state="!!cardExpMonth"></b-form-select>
              </b-form-group>
              <b-form-group
                label="Card Expiration Year"
                :state="!!(cardExpYear)"
                invalid-feedback="A valid year is required."
              >
                <b-form-select id="cardExpYear" v-model="cardExpYear" :options="[2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032]"
                               :required="cardTabIsActive" :state="!!cardExpYear"></b-form-select>
              </b-form-group>
            </b-form>
          </b-tab>
        </b-tabs>
      </b-card>

      <b-card title="Payment Schedule" class="mt-4" v-if="!paymentWithAppMade">
        <b-form>
          <b-form-group>
            <b-form-checkbox
              v-model="paymentWithApp"
            >
              If approved, pay first month's premium when this application is submitted.
            </b-form-checkbox>
          </b-form-group>

          Please pay premium according to the
          <b-form-radio-group
            class="pw-2"
            id="radio-group-1"
            v-model="paymentScheduleType"
            :options="paymentScheduleTypes"
            name="paymentScheduleType"
            buttons
            size="sm"
            button-variant="outline-primary"
          >
          </b-form-radio-group>
          of the month:
        </b-form>

        <div class="m-2">
          <b-form inline>
            <div v-show="paymentScheduleType === 'monthly-day-of-month'">
              on the
              <b-form-select size="sm" v-model="paymentScheduleDayOfMonth"
                             :options="paymentScheduleDaysOfMonth"
              ></b-form-select>
              of each month.
            </div>
            <div v-show="paymentScheduleType === 'monthly-day-of-week'">
              on the
              <b-form-select size="sm" v-model="paymentScheduleWeekdayOccurrence"
                             :options="paymentScheduleWeeksOfMonth"
              ></b-form-select>
              <b-form-select size="sm" v-model="paymentScheduleDayOfWeek"
                             :options="paymentScheduleDaysOfWeek"

              ></b-form-select>
              of each month.
            </div>
          </b-form>

        </div>

        <p v-if="firstNextPaymentDateString">Your {{paymentFirstNextText}} will be <strong><span
          v-html="firstNextPaymentDateString"></span></strong></p>
        <p v-else><em>Please choose the payment schedule information.</em></p>
      </b-card>

      <b-alert variant="danger" :show="pciErrors.length > 0" class="mt-2 mb-2">
        <div>Processing payment info resulted in these errors:</div>
        <ul>
          <li v-for="error in pciErrors">{{ error }}</li>
        </ul>
      </b-alert>

      <div class="text-right mt-4" v-if="!paymentWithAppMade">
        <b-button v-if="paymentInformationCaptured" @click="saveSchedule" variant="success" :disabled="!canUpdatePaymentSchedule">Update Payment Schedule</b-button>
        <b-button v-else @click="submitPaymentInformation" variant="success" :disabled="!canSubmitPaymentInformation">Save Payment Information</b-button>
      </div>

    </div>
  </b-overlay>
</template>

<script>
import moment from "../vendor/moment-timezone-with-data-1970-2030.js";
import Api from "../api";
import {Applicant, ApplicantList, Coverage, CoverageList, PremiumMode, ProductList} from "./models";
import bus from "./messages";

export default {
  name: "PaymentInformationCapture",
  props: {
    applicants: {
      type: ApplicantList,
      required: true,
    },
    products: {
      type: ProductList,
      required: false,
    },
    selectedCoverages: {
      type: CoverageList,
      required: true
    },
    applicantContext: {
      type: Applicant,
      required: false
    },
    coverageContext: {
      type: Coverage,
      required: false
    },
    premiumMode: {
      type: PremiumMode,
      required: true
    },
    globalBeneficiaries: {
      type: Array,
      required: false
    },
    paymentInformation: {
      type: Object,
      required: false
    },
    // this is filled-in from the backend
    currentPaymentSchedule: {
      type: Object,
      required: false,
    },
    paymentWithAppDefault: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      showOverlay: false,
      paymentCustomerId: null,
      paymentMethodId: null,

      paymentWithApp: null,
      paymentWithAppDate: null,
      paymentWithAppProcessorId: null,

      bankTabIsActive: true,
      cardTabIsActive: false,

      cardNumber: '',
      cardExpMonth: '',
      cardExpYear: '',
      cardCvv: '',

      bankType: 'checking', // or 'savings'
      bankHolderType: 'personal', // or 'business'
      bankAccount: null,
      bankRouting: null,

      // see computed paymentMethodType, which is computed from the active tab index
      paymentScheduleType: 'monthly-day-of-month', // or 'monthly-day-of-week'
      paymentScheduleTypes: [
        {value: 'monthly-day-of-month', text: 'day'},
        {value: 'monthly-day-of-week', text: 'week'},
      ],
      paymentScheduleDayOfMonth: 1, // should be 1-28
      // see computed paymentScheduleDaysOfMonth
      paymentScheduleDayOfWeek: 3, // 0-6
      paymentScheduleWeekdayOccurrence: 1, // 1-4
      paymentScheduleWeeksOfMonth: [
        {value: 1, text: 'First'},
        {value: 2, text: 'Second'},
        {value: 3, text: 'Third'},
        {value: 4, text: 'Fourth'},
      ],
      paymentScheduleDaysOfWeek: [
        {value: 0, text: 'Sunday'},
        {value: 1, text: 'Monday'},
        {value: 2, text: 'Tuesday'},
        {value: 3, text: 'Wednesday'},
        {value: 4, text: 'Thursday'},
        {value: 5, text: 'Friday'},
        {value: 6, text: 'Saturday'},
      ],
      // Examples from Stax docs:
      // **4th SUNDAY EVERY MONTH**
      // FREQ=MONTHLY;BYSETPOS=4;BYDAY=SU;INTERVAL=1
      // **17th of every month**
      // FREQ=MONTHLY;BYMONTHDAY=17;INTERVAL=1

      pciErrors: [],

      canContinue: false,
      canUpdatePaymentSchedule: false,
      paymentScheduleHasChanged: false,

      cardType: null,
      cardNumberIsValid: false,
      cardCvvIsValid: false,

      // this is initialized in created()
      bankProcessor: null,
      cardProcessor: null,

      bankFormValidate: false,
      cardFormValidate: false,
    }
  },
  created() {
    // initialize with the default prop
    this.paymentWithApp = this.paymentWithAppDefault;
    console.debug(`Applied default paymentWithApp=${this.paymentWithApp}`);

    this.initBankProcessor();
    this.initCardProcessor();

    if (this.currentPaymentSchedule) {
      this._applyCurrentPaymentScheduleLocally();
    } else {
      // set defaults for first time, based on current date
      this.paymentScheduleDayOfMonth = this._defaultDayOfMonth;
    }

    // emit the value on the global event bus so other components have
    // the newest payment schedule info
    this.emitValue();

    // set state of the Next button upon creation
    bus.$emit('update-force-next-step-disabled', !(this.paymentMethodId && this.paymentCustomerId));
  },
  watch: {
    currentPaymentSchedule() {
      // update the value locally
      this._applyCurrentPaymentScheduleLocally();
    },

    paymentWithApp: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.canUpdatePaymentSchedule = true;
      }
    },


    canContinue(newVal, oldVal) {
      // the question is "next step disabled", which is the opposite of "canContinue", so we pass the inverse: !newVal
      bus.$emit('update-force-next-step-disabled', !newVal);
    },
    cardTabIsActive(newVal, oldVal) {
      if (!!newVal) {
        this.initCardForm();
      }
    }
  },

  computed: {
    _defaultDayOfMonth() {
      return 1;
      // We removed this logic per request from CELIC - EF 2022-09-28
      // let defaultDayOfMonth = new Date().getDate();
      // if (defaultDayOfMonth < 1 || defaultDayOfMonth > 28) {
      //   defaultDayOfMonth = 1;
      // }
      // return defaultDayOfMonth;
    },

    paymentFirstNextText() {
      if (this.paymentWithApp === true) {
        return 'next payment after today';
      }
      return 'first payment and policy effective date';
    },

    paymentWithAppMade() {
      // If paymentWithAppProcessorId is Truthy, a *payment* was made for this app.
      // We should not allow any change to payment information.
      return (!!this.paymentWithAppProcessorId)
    },

    paymentMethodType() {
      if (this.bankTabIsActive) {
        return 'bank';
      }
      return 'card';
    },

    paymentInformationCaptured() {
      return this.paymentMethodId && this.paymentCustomerId;
    },

    paymentScheduleDaysOfMonth() {
      const ints = [...Array(28).keys()];
      return ints.map(i => {
        return {'value': i + 1, text: i + 1}
      });
    },

    firstNextPaymentDate() {
      const now = moment().hours(0).minutes(0).seconds(0);
      let scheduled = moment().hours(0).minutes(0).seconds(0).date(1);
      console.log('now', now.format());
      console.log('scheduled', scheduled.format());

      if (this.paymentScheduleType === "monthly-day-of-month") {

        if (!this.paymentScheduleDayOfMonth) {
          return;
        }

        scheduled.date(this.paymentScheduleDayOfMonth);
        console.log('scheduled', scheduled.format(), "after setting day of month to ", this.paymentScheduleDayOfMonth);

        // add a month if the day of month is in the past
        if (now >= scheduled) {
          scheduled.add(1, 'month');
          console.log('scheduled', scheduled.format(), "after adding a month");
        }

      } else if (this.paymentScheduleType === "monthly-day-of-week") {
        if (typeof this.paymentScheduleDayOfWeek !== 'number'  || typeof this.paymentScheduleWeekdayOccurrence !== 'number') {
          return;
        }

        scheduled = this.getDateOfWeekdayOccurrence(
          this.paymentScheduleDayOfWeek,
          this.paymentScheduleWeekdayOccurrence,
          now.month(),
          now.year()
        );
        console.log('scheduled', scheduled.format(), "after setting day of week to ", this.paymentScheduleDayOfWeek, "and occurrence to ", this.paymentScheduleWeekdayOccurrence);

        // add a month if the day of month is in the past
        if (now >= scheduled) {
          scheduled.add(1, 'month');
        }
        console.log('scheduled', scheduled.format(), "after adding a month");

      } else {
        return;
      }

      // if (this.paymentWithApp) {
      //   const daysOutThresholdDate = moment().hour(0).minute(0).second(0).add(46, 'days');
      //   const nextPaymentDate = scheduled.clone();
      //   let subsequentPaymentDate = null;
      //
      //   // the way we do this differs based on the schedule type
      //   if (this.paymentScheduleType === "monthly-day-of-week") {
      //     subsequentPaymentDate = nextPaymentDate.clone().add(5, 'weeks').day(this.paymentScheduleDayOfWeek);
      //   } else { // otherwise it must be "monthly-day-of-month:
      //     subsequentPaymentDate = nextPaymentDate.clone().add(1, 'months');
      //   }
      //
      //   if (moment(subsequentPaymentDate).isBefore(daysOutThresholdDate)) {
      //     scheduled = subsequentPaymentDate;
      //   } else {
      //     scheduled = nextPaymentDate;
      //   }
      // }

      return scheduled;
    },

    firstNextPaymentDateString() {
      if (!this.firstNextPaymentDate) {
        return '';
      }
      return this.firstNextPaymentDate.format('dddd, MMMM Do YYYY');
    },

    canSubmitPaymentInformation() {
      if (this.paymentInformationCaptured) {
        return false;
      }

      if (this.bankTabIsActive) {
        if (this.bankAccount && this.bankRouting && this.bankRoutingIsValid) {
          return true;
        }

      } else if (this.cardTabIsActive) {
        if (this.cardExpMonth && this.cardExpYear && this.cardNumberIsValid && this.cardCvvIsValid) {
          return true;
        }
      }
      return false;
    },

    bankRoutingIsValid() {
      if (this.bankRouting) {
        return this.bankRouting.length === 9;
      }
      return null;
    }
  },
  methods: {
    _applyCurrentPaymentScheduleLocally() {
      this.paymentMethodId = this.currentPaymentSchedule.payment_method_token;
      this.paymentCustomerId = this.currentPaymentSchedule.customer_token;
      this.paymentScheduleType = this.currentPaymentSchedule.schedule_type;
      this.paymentScheduleDayOfMonth = this.currentPaymentSchedule.day_of_month;
      this.paymentScheduleDayOfWeek = this.currentPaymentSchedule.day_of_week;
      this.paymentScheduleWeekdayOccurrence = this.currentPaymentSchedule.weekday_occurrence;
      this.paymentWithApp = this.currentPaymentSchedule.payment_with_app;
      this.paymentWithAppDate = this.currentPaymentSchedule.payment_with_app_date;
      this.paymentWithAppProcessorId = this.currentPaymentSchedule.payment_with_app_processor_id;
    },

    initCardProcessor() {
      this.cardProcessor = new FattJs(process.env.VUE_APP_STAX_WEB_PAYMENTS_TOKEN, {
        number: {
          id: 'cardNumber',     // the html id of the div you want to contain the credit card number field
          placeholder: '0000 0000 0000 0000',    // the placeholder the field should contain
          style: 'height: 100%; width: 100%; font-size: 16px; line-height: 16px;',    // the style to apply to the field
          format: 'prettyFormat'    // the formatting of the CC number (prettyFormat || plainFormat || maskedFormat)
        },
        cvv: {
          id: 'cardCVV',    // the html id of the div you want to contain the cvv field
          placeholder: '000',    // the placeholder the field should contain
          type: 'text',    // the input type (optional)
          style: 'height: 100%; width: 100%; font-size: 16px; line-height: 16px;',    // the style to apply to the field
          // format: 'maskedFormat'
        }
      })
    },
    initBankProcessor() {
      this.bankProcessor = new FattJs(process.env.VUE_APP_STAX_WEB_PAYMENTS_TOKEN, {});
    },

    initCardForm() {
      this.cardProcessor
        .showCardForm()
          .then((handler) => {
            this.cardProcessor.on("card_form_incomplete", (message) => {
              this.cardType = message.cardType;
              this.cardNumberIsValid = !!message.validNumber;
              this.cardCvvIsValid = !!message.validCvv;
            });
            this.cardProcessor.on("card_form_complete", (message) => {
              this.cardType = message.cardType;
              this.cardNumberIsValid = !!message.validNumber;
              this.cardCvvIsValid = !!message.validCvv;
            });
          })
          .catch((err) => {
            console.error("There was an error loading the credit card form: ", err);
          });
    },

    clearPaymentInformation() {
      this.paymentCustomerId = null;
      this.paymentMethodId = null;
      this.emitValue();
    },

    cardFormIsValid() {
      if (!this.$refs.cardForm.checkValidity()) {
        return false;
      }
      if (!this.cardNumberIsValid) {
        return false;
      }
      if (!this.cardCvvIsValid) {
        return false;
      }
    },

    submitPaymentInformation() {
      // enable the curtain to hide
      this.showOverlay = true;

      this.pciErrors = [];

      let processor = null;

      let paymentInformation = {
        firstname: this.applicantContext.getFirstName(),
        lastname: this.applicantContext.getLastName(),
        person_name: this.applicantContext.getName(),
        // phone: this.applicantContext.getPhone(),
        address_1: this.applicantContext.getAddress(),
        address_2: this.applicantContext.getAddress2(),
        address_city: this.applicantContext.getApplicantCity(),
        address_state: this.applicantContext.getApplicantState(),
        address_zip: this.applicantContext.getApplicantZip(),
        method: this.paymentMethodType,
        validate: false,
      };

      if (this.bankTabIsActive) {
        if (!this.$refs.bankForm.checkValidity()) {
          return false;
        }

        processor = this.bankProcessor;

        Object.assign(
          paymentInformation,
          {
            bank_type: this.bankType,
            bank_account: this.bankAccount,
            bank_routing: this.bankRouting,
            bank_holder_type: this.bankHolderType,
          }
        );


      } else if (this.cardTabIsActive) {
        if (!this.cardFormIsValid()) {

          processor = this.cardProcessor;

          Object.assign(
            paymentInformation,
            {
              month: this.cardExpMonth,
              year: this.cardExpYear,
            }
          );
        }
      }

      processor
        .tokenize(paymentInformation)
        .then((response) => {
          this.paymentMethodId = response.id;
          this.paymentCustomerId = response.customer_id;
          return this.saveSchedule();
        })
        .catch((err) => {
          this.pciErrors = [];
          if (err.key && err.key === 'errors.expired') {
            this.pciErrors = ['The provided exp month and year has passed.']
          } else if (err.errors) {
            this.pciErrors = err.errors;
          }
        })
        .finally(() => {
          this.showOverlay = false;
        })
    },

    saveSchedule() {
      if (!this.firstNextPaymentDateString) {
        return;
      }

      // enable the curtain to hide
      this.showOverlay = true;

      this.canContinue = false;

      this.canUpdatePaymentSchedule = false;

      let scheduleData = {
        schedule_type: this.paymentScheduleType,
        day_of_month: this.paymentScheduleDayOfMonth,
        day_of_week: this.paymentScheduleDayOfWeek,
        weekday_occurrence: this.paymentScheduleWeekdayOccurrence,
        customer_token: this.paymentCustomerId,
        payment_method_token: this.paymentMethodId,
        payment_with_app: this.paymentWithApp,
        next_payment_date: this.firstNextPaymentDate,
        // don't update payment_with_app_date or payment_with_app_processor_id! They should only be set
        // by the backend.
      };

      return Api.savePaymentSchedule(scheduleData)
        .then((response) => {
            this.paymentMethodId = response.payment_method_token;
            this.paymentCustomerId = response.customer_token;

            // so the value can be updated on the event bus
            this.emitValue();

            // now we are good to advance
            this.canContinue = true;
          })
          .catch((err) => {
            this.canUpdatePaymentSchedule = true;  // re-enable because something happened
            this.pciErrors = [];
            for (const errKey in err) {
              let errorMessage = err[errKey];
              // strip off the prefix from the error message "Could not tokenize payment method:"
              errorMessage = errorMessage.replace(/Could not tokenize payment method:/, '').trim();
              // replace any remaining instance of "tokenize" with "save" because that sounds a lot nicer
              errorMessage = errorMessage.replace(/tokenize/g, 'save');
              this.pciErrors.push(errorMessage);
            }
          })
          .finally(() => {
            this.showOverlay = false;
          })
    },

    emitValue() {
      bus.$emit("payment-information-updated", {
        paymentMethodId: this.paymentMethodId,
        paymentCustomerId: this.paymentCustomerId,
        paymentScheduleType: this.paymentScheduleType,
        paymentScheduleDayOfMonth: this.paymentScheduleDayOfMonth,
        paymentScheduleDayOfWeek: this.paymentScheduleDayOfWeek,
        paymentScheduleWeekdayOccurrence: this.paymentScheduleWeekdayOccurrence,
        paymentWithApp: this.paymentWithApp,
        paymentWithAppMade: this.paymentWithAppMade,
      });
    },


    // weekday and month params are 0-index
    getDateOfWeekdayOccurrence(weekday, occurrence, month, year) {
      let currentOccurrence = 0;
      const maxOccurrence = 4;
      const maxDateDay = 28;
      let startDate = new Date(year, month, 1);
      const now = new Date()

      for (let x = 0; x < maxDateDay; x++) {
        let currentSearchDate = new Date(startDate.getFullYear(), startDate.getMonth(), x + 1);
        if (currentSearchDate.getDay() === weekday) {
          // this is the right day...
          currentOccurrence++;

          if (currentOccurrence === occurrence) { // this is the nth occurrence

            if (now > currentSearchDate) {
              let newInvoiceDate = this.getDateOfWeekdayOccurrence(weekday, occurrence, month + 1, year);
              return newInvoiceDate;
            }

            return moment(currentSearchDate);

          }
        }
      }

      if (currentOccurrence > maxOccurrence) {
        console.error(`getDateOfWeekdayOccurrence had an error -- did not find day with given occurrence ${occurrence} of weekday ${weekday}`);
      }

      return null;
    }
  }
}
</script>

<style scoped>
/* this styles the <IFRAME>d form fields that Fatt.js creates, so they look
   consistent with our Bootstrap 4 styling - EF 2022-03-25 */
#cardCVV, #cardNumber {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
</style>
